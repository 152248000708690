import type { ComponentProps } from "react"
import { TooltipContent as TooltipContentBase } from "@/v2-ui/tooltip/base/TooltipBase"
import cn from "@/v2-ui/utils/utils.cn"

export type TooltipContentProps = ComponentProps<typeof TooltipContentBase>

function TooltipContent(props: TooltipContentProps) {
  const { className, ...tooltipContentBaseProps } = props
  return (
    <TooltipContentBase
      className={cn("ui-tooltip-content", className)}
      {...tooltipContentBaseProps}
    />
  )
}

export default TooltipContent

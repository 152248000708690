import type { ComponentProps } from "react"
import { Label as LabelBase } from "@/v2-ui/label/base/LabelBase"
import cn from "@/v2-ui/utils/utils.cn"

export type LabelProps = ComponentProps<typeof LabelBase>

function Label(props: LabelProps) {
  const { className, ...labelProps } = props
  return (
    <LabelBase
      className={cn("ui-label", className)}
      {...labelProps}
    />
  )
}

export default Label

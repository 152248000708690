import type { DisplaySize } from "@/v2-ui/constants"

const displaySizeScale = {
  "xl": 5,
  "lg": 4,
  "md": 3,
  "sm": 2,
  "xs": 1
}

const displaySizeTexts = {
  "xl": "XLARGE",
  "lg": "LARGE",
  "md": "MEDIUM",
  "sm": "SMALL",
  "xs": "XSMALL"
}

export function displaySizeToScale(displaySize: DisplaySize) {
  return displaySizeScale[displaySize]
}

export function displaySizeToText(displaySize: keyof typeof displaySizeTexts) {
  return displaySizeTexts[displaySize]
}

export function getDisplaySizeClassName(
  prefix: string,
  displaySize?: DisplaySize
) {
  if(!displaySize) return ""
  return `${prefix}-${displaySize}`
}

export type CnInput = Record<string, boolean | string> | string | string[]

const hasOwn = {}.hasOwnProperty

/**
 * Utility function that produces a single className from
 * more than one string/array/object of classes. Used to mix together
 * classes for an element.
 */
function cn(...input: CnInput[]) {
  const classes = []

  for(const arg of input) {
    if(!arg) continue
    const argType = typeof arg

    if(argType === "string" || argType === "number") {
      classes.push(arg)
    } else if(Array.isArray(arg)) {
      if(arg.length > 0) {
        // eslint-disable-next-line prefer-spread
        const inner = cn.apply(null, arg)
        if(inner) {
          classes.push(inner)
        }
      }
    } else if(argType === "object") {
      if(arg.toString !== Object.prototype.toString
        && !arg.toString.toString().includes("[native code]")
      ) {
        classes.push(arg.toString())
        continue
      }

      for(const key in arg as object) {
        if(hasOwn.call(arg, key) && arg[key]) {
          classes.push(key)
        }
      }
    }
  }

  return classes.join(" ")
}

export default cn

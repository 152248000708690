import type { ComponentProps } from "react"
import { SidebarInput as SidebarInputBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarInputProps = ComponentProps<typeof SidebarInputBase>

function SidebarInput(props: SidebarInputProps) {
  const { className, ...sidebarInputBaseProps } = props
  return (
    <SidebarInputBase
      className={cn("ui-sidebar-input", className)}
      {...sidebarInputBaseProps}
    />
  )
}

export default SidebarInput

import type { ThemeMode, ThemeConfig } from "@/v2-ui/theme/theme.types"
import { type BaseColor, baseColors } from "./theme.baseColors"

export const LOCAL_STORAGE_KEY = "global_theme"

export const THEME_MODES: Record<ThemeMode, ThemeMode> = {
  light: "light",
  dark: "dark"
}
export const AVAILABLE_THEME_MODES: ThemeMode[] = [ THEME_MODES.light, THEME_MODES.dark ]

export const AVAILABLE_THEMES: BaseColor["name"][] = baseColors.map(b => b.name)
export const DEFAULT_THEME: BaseColor["name"] = "zinc"

export const DEFAULT_THEME_RADIUS = 0.5
export const DEFAULT_THEME_FONTSIZE = 16

export const DEFAULT_THEME_CONFIG_BY_MODE: Record<ThemeMode, ThemeConfig> = {
  light: {
    mode: THEME_MODES.light,
    theme: DEFAULT_THEME,
    radius: DEFAULT_THEME_RADIUS,
    fontSize: DEFAULT_THEME_FONTSIZE
  },
  dark: {
    mode: THEME_MODES.dark,
    theme: DEFAULT_THEME,
    radius: DEFAULT_THEME_RADIUS,
    fontSize: DEFAULT_THEME_FONTSIZE
  }
}

export const DEFAULT_THEME_CONFIG: ThemeConfig = DEFAULT_THEME_CONFIG_BY_MODE.dark

import { createContext, useContext } from "react"
import type { ThemeConfig } from "@/v2-ui/theme/theme.types"
import {
  DEFAULT_THEME_CONFIG
} from "@/v2-ui/theme/theme.constants"

export type ThemeContextProviderValue = {
  theme: ThemeConfig | null,
  setTheme: (theme: ThemeConfig) => void,
  toggleDarkMode: () => void
}

export const DEFAULT_THEME_CTX: ThemeContextProviderValue = {
  theme: DEFAULT_THEME_CONFIG,
  setTheme: () => { },
  toggleDarkMode: () => { }
}

export const ThemeContext = createContext<ThemeContextProviderValue>(DEFAULT_THEME_CTX)

export function useThemeContext() {
  return useContext(ThemeContext)
}

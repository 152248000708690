import type { ComponentProps } from "react"
import { Command as CommandBase } from "@/v2-ui/command/base/CommandBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CommandProps = ComponentProps<typeof CommandBase>

function Command(props: CommandProps) {
  const { className, ...commandBaseProps } = props
  return (
    <CommandBase
      className={cn("ui-command", className)}
      {...commandBaseProps}
    />
  )
}

export default Command

import "./sidebar.css"
import Sidebar from "./Sidebar"
import SidebarContent from "./SidebarContent"
import SidebarFooter from "./SidebarFooter"
import SidebarGroup from "./SidebarGroup"
import SidebarGroupAction from "./SidebarGroupAction"
import SidebarGroupContent from "./SidebarGroupContent"
import SidebarGroupLabel from "./SidebarGroupLabel"
import SidebarHeader from "./SidebarHeader"
import SidebarInput from "./SidebarInput"
import SidebarInset from "./SidebarInset"
import SidebarMenu from "./SidebarMenu"
import SidebarMenuAction from "./SidebarMenuAction"
import SidebarMenuBadge from "./SidebarMenuBadge"
import SidebarMenuButton from "./SidebarMenuButton"
import SidebarMenuItem from "./SidebarMenuItem"
import SidebarMenuSkeleton from "./SidebarMenuSkeleton"
import SidebarMenuSub from "./SidebarMenuSub"
import SidebarMenuSubButton from "./SidebarMenuSubButton"
import SidebarMenuSubItem from "./SidebarMenuSubItem"
import SidebarProvider from "./SidebarProvider"
import SidebarRail from "./SidebarRail"
import SidebarSeparator from "./SidebarSeparator"
import SidebarTrigger from "./SidebarTrigger"

export type { SidebarProps } from "./Sidebar"
export type { SidebarContentProps } from "./SidebarContent"
export type { SidebarFooterProps } from "./SidebarFooter"
export type { SidebarGroupProps } from "./SidebarGroup"
export type { SidebarGroupActionProps } from "./SidebarGroupAction"
export type { SidebarGroupContentProps } from "./SidebarGroupContent"
export type { SidebarGroupLabelProps } from "./SidebarGroupLabel"
export type { SidebarHeaderProps } from "./SidebarHeader"
export type { SidebarInputProps } from "./SidebarInput"
export type { SidebarInsetProps } from "./SidebarInset"
export type { SidebarMenuProps } from "./SidebarMenu"
export type { SidebarMenuActionProps } from "./SidebarMenuAction"
export type { SidebarMenuBadgeProps } from "./SidebarMenuBadge"
export type { SidebarMenuButtonProps } from "./SidebarMenuButton"
export type { SidebarMenuItemProps } from "./SidebarMenuItem"
export type { SidebarMenuSkeletonProps } from "./SidebarMenuSkeleton"
export type { SidebarMenuSubProps } from "./SidebarMenuSub"
export type { SidebarMenuSubButtonProps } from "./SidebarMenuSubButton"
export type { SidebarMenuSubItemProps } from "./SidebarMenuSubItem"
export type { SidebarProviderProps } from "./SidebarProvider"
export type { SidebarRailProps } from "./SidebarRail"
export type { SidebarSeparatorProps } from "./SidebarSeparator"
export type { SidebarTriggerProps } from "./SidebarTrigger"

export { useSidebar } from "./base/SidebarBase"

export default Object.assign(Sidebar, {
  Content: SidebarContent,
  Footer: SidebarFooter,
  Group: Object.assign(SidebarGroup, {
    Action: SidebarGroupAction,
    Content: SidebarGroupContent,
    Label: SidebarGroupLabel
  }),
  Header: SidebarHeader,
  Input: SidebarInput,
  Inset: SidebarInset,
  Menu: Object.assign(SidebarMenu, {
    Action: SidebarMenuAction,
    Badge: SidebarMenuBadge,
    Button: SidebarMenuButton,
    Item: SidebarMenuItem,
    Skeleton: SidebarMenuSkeleton,
    Sub: Object.assign(SidebarMenuSub, {
      Button: SidebarMenuSubButton,
      Item: SidebarMenuSubItem
    })
  }),
  Provider: SidebarProvider,
  Rail: SidebarRail,
  Separator: SidebarSeparator,
  Trigger: SidebarTrigger
})

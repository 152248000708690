import type { ComponentProps } from "react"
import { DropdownMenuSeparator as DropdownMenuBaseSeparatorBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"
import cn from "@/v2-ui/utils/utils.cn"

export type DropdownMenuSeparatorProps = ComponentProps<typeof DropdownMenuBaseSeparatorBase>

function DropdownMenuSeparator(props: DropdownMenuSeparatorProps) {
  const { className, ...dropdownMenuBaseSeparatorBaseProps } = props

  return (
    <DropdownMenuBaseSeparatorBase
      className={cn("ui-dropdown-menu-separator", className)}
      {...dropdownMenuBaseSeparatorBaseProps}
    />
  )
}

export default DropdownMenuSeparator

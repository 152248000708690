/* eslint-disable no-console */
import type { CaptureContext } from "@sentry/types"
import { captureException, captureMessage } from "@sentry/react"
import config from "@/v2-common/config"

export function logError(error: Error | string, context?: CaptureContext) {
  if(config.isProd) {
    captureException(error, context)
  } else {
    console.error(error, context)
  }
}

export function logWarning(message: string, context?: CaptureContext) {
  if(config.isProd) {
    captureMessage(message, context)
  } else {
    console.warn(message, context)
  }
}

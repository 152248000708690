import type { ComponentProps } from "react"
import { CommandItem as CommandItemBase } from "@/v2-ui/command/base/CommandBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CommandItemProps = ComponentProps<typeof CommandItemBase>

function CommandItem(props: CommandItemProps) {
  const { className, ...commandItemBaseProps } = props
  return (
    <CommandItemBase
      className={cn("ui-command-item", className)}
      {...commandItemBaseProps}
    />
  )
}

export default CommandItem

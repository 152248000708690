import { useEffect } from "react"
import { useNavigate } from "@/v2-console-shared/utils/utils.router"

function Redirect() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/map", { replace: false })
  }, [
    navigate
  ])
  return null
}

export default Redirect

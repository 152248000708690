export const ONE_HOUR_IN_MS = 3600000

export const DEFAULT_COUNTRY_CODE = 578
export const DEFAULT_TIMEZONE = "W. Europe Standard Time"
export const EXTRA_PANELS_BEFORE_ID = "extra-panels-before"
export const EXTRA_PANELS_BEFORE_SELECTOR = `#${EXTRA_PANELS_BEFORE_ID}`
export const EXTRA_PANELS_AFTER_ID = "extra-panels-after"
export const EXTRA_PANELS_AFTER_SELECTOR = `#${EXTRA_PANELS_AFTER_ID}`
export const EXTRA_PANELS_OUTSIDE_AFTER_ID = "extra-panels-outside-after"
export const EXTRA_PANELS_OUTSIDE_AFTER_SELECTOR = `#${EXTRA_PANELS_OUTSIDE_AFTER_ID}`

export const MATCHING_LAYER_POPUP_ID = "matching-layer-popup"
export const MATCHING_LAYER_POPUP_SELECTOR = `#${MATCHING_LAYER_POPUP_ID}`

export const NAVLAYER_POPUP_ID = "nav-layer_popup"
export const NAVLAYER_POPUP_SELECTOR = `#${NAVLAYER_POPUP_ID}`

import type { ComponentProps } from "react"
import { SidebarMenu as SidebarMenuBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarMenuProps = ComponentProps<typeof SidebarMenuBase>

function SidebarMenu(props: SidebarMenuProps) {
  const { className, ...sidebarMenuBaseProps } = props
  return (
    <SidebarMenuBase
      className={cn("ui-sidebar-menu", className)}
      {...sidebarMenuBaseProps}
    />
  )
}

export default SidebarMenu

import cn from "@/v2-ui/utils/utils.cn"
import Frame, { type FrameBodyProps } from "@/v2-ui/frame"

export type DialogBodyProps = FrameBodyProps

function DialogBody(props: DialogBodyProps) {
  const { className, ...frameBodyProps } = props
  return (
    <Frame.Body
      className={cn("dialog-body", className)}
      {...frameBodyProps}
    />
  )
}

export default DialogBody

import { forwardRef, type ComponentProps } from "react"
import { TooltipTrigger as TooltipTriggerBase } from "@/v2-ui/tooltip/base/TooltipBase"

export type TooltipTriggerProps = ComponentProps<typeof TooltipTriggerBase>

function TooltipTrigger(props: TooltipTriggerProps, ref) {
  const { asChild = true, ...tooltipTriggerBase } = props
  return (
    <TooltipTriggerBase
      ref={ref}
      asChild={asChild}
      {...tooltipTriggerBase}
    />
  )
}

export default forwardRef(TooltipTrigger)

import "./toast.css"
import Toast from "./Toast"
import ToastClose from "./ToastClose"
import ToastDescription from "./ToastDescription"
import ToastTitle from "./ToastTitle"
import ToastAction from "./ToastAction"
// import { Toast, ToastClose, ToastDescription, ToastTitle } from "./base/ToastBase"

import { ToastProvider } from "./base/ToastBase"
import { default as ToastViewport } from "./ToastViewport"
export type { ToastProps } from "./Toast"

export default Object.assign(Toast, {
  Title: ToastTitle,
  Description: ToastDescription,
  Close: ToastClose,
  Provider: ToastProvider,
  Viewport: ToastViewport,
  Action: ToastAction
})

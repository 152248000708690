import { useState, useCallback } from "react"
import { safeJSONParse } from "@/v2-common/utils/utils.misc"

export function getValue<T>(key: string, initialValue: T = null) {
  const item = window.localStorage.getItem(key)
  if(!item) return initialValue
  return safeJSONParse<T>(item) || initialValue
}

function useLocalStorage<T>(key: string, initialValue: T = null) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [ value, setValue ] = useState<T>(() => {
    return getValue(key, initialValue)
  })

  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  const setItem = useCallback((value: T) => {
    try {
      setValue(value)
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch(error) {
      console.error(error)
    }
  }, [ key, setValue ])

  const removeItem = useCallback(()=> {
    if(value) {
      window.localStorage.removeItem(key)
    }
  }, [ key, value ])

  return {
    value,
    hasValue: !!value,
    setItem,
    removeItem
  } as const
}

export type Store<T> = ReturnType<typeof useLocalStorage<T>>
export default useLocalStorage

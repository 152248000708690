import { useQuery, type QueryHookOptions } from "@apollo/client"
import { useLazyQuery, type LazyQueryHookOptions } from "@apollo/client"
import query from "./currentUser.country.query.gql"
import type {
  CurrentUserCountryQuery,
  CurrentUserCountryQueryVariables
} from "./__types__/currentUser.country.query"

type LazyOptions = LazyQueryHookOptions<
CurrentUserCountryQuery,
CurrentUserCountryQueryVariables
>

export function useCurrentUserCountryLazyQuery(options?: LazyOptions) {
  return useLazyQuery<
    CurrentUserCountryQuery,
    CurrentUserCountryQueryVariables
  >(query, options)
}

type Options = QueryHookOptions<
CurrentUserCountryQuery,
CurrentUserCountryQueryVariables
>

function useCurrentUserCountryQuery(options?: Options) {
  return useQuery<
    CurrentUserCountryQuery,
    CurrentUserCountryQueryVariables
  >(query, options)
}

export default useCurrentUserCountryQuery

/* eslint-disable react/jsx-max-props-per-line */
import { lazy } from "react"
import { Routes as ReactRoutes, Route } from "react-router-dom"
import { ROUTES as R } from "@/v2-console/router/router.constants"
import { lazyRetry } from "@/v2-console-shared/utils/utils.router"
import AuthGuard from "@/v2-console/auth/AuthGuard"
import AppOutlet from "@/v2-console/app/AppOutlet"
import Redirect from "@/v2-console/router/Redirect"

const MapPage = lazy(() => lazyRetry(() => import("@/v2-console/map/MapPage")))
const AuthRedeemInvitationPage = lazy(() => lazyRetry(() => import("@/v2-console/auth/AuthRedeemInvitationPage")))
const AuthLoginPage = lazy(() => lazyRetry(() => import("@/v2-console/auth/AuthLoginPage")))
const AuthLogoutPage = lazy(() => lazyRetry(() => import("@/v2-console/auth/AuthLogoutPage")))
const SettingPanel = lazy(() => lazyRetry(() => import("@/v2-console/setting/SettingPanel")))
const AddressPanel = lazy(() => lazyRetry(() => import("@/v2-console/address/AddressPanel")))
const LayerListPanel = lazy(() => lazyRetry(() => import("@/v2-console/layer/LayerPanel")))

function Routes() {
  return (
    <ReactRoutes>
      <Route path="/" element={<AuthGuard><AppOutlet /></AuthGuard>}>
        <Route path="/map" element={<MapPage />}>
          <Route path={R.SETTING_PANEL.template} element={<SettingPanel />} />
          <Route path={R.ADDRESS_PANEL.template} element={<AddressPanel />} />
          <Route path={R.MAP_LAYER_LIST.template} element={<LayerListPanel />} />
        </Route>
        <Route path="/" element={<Redirect />} />
      </Route>
      <Route path={R.REDEEM_INVITATION_PAGE.template} element={<AuthRedeemInvitationPage />} />
      <Route path={R.LOGIN_PAGE.template} element={<AuthLoginPage />} />
      <Route path={R.LOGOUT_PAGE.template} element={<AuthLogoutPage />} />
      <Route path="*" element={<Redirect />} />
    </ReactRoutes>
  )
}

export default Routes

import { withProfiler, ErrorBoundary } from "@sentry/react"
import ErrorFallback from "@/v2-console/error/ErrorFallback"
import Router from "@/v2-console/router/Router"
import ThemeProviderWithLocalStorage from "@/v2-ui/theme/ThemeProviderWithLocalStorage"
import Toaster from "@/v2-ui/toaster"
import Tooltip from "@/v2-ui/tooltip"

function App() {
  return (
    <ErrorBoundary
      showDialog
      fallback={ErrorFallback}
    >
      <ThemeProviderWithLocalStorage>
        <Tooltip.Provider>
          <Toaster />
          <Router />
        </Tooltip.Provider>
      </ThemeProviderWithLocalStorage>
    </ErrorBoundary>
  )
}

export default withProfiler(App)

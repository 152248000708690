import type { ComponentProps } from "react"
import { DropdownMenuRadioGroup as DropdownMenuRadioGroupBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"

export type DropdownMenuRadioGroupProps = ComponentProps<typeof DropdownMenuRadioGroupBase>

function DropdownMenuRadioGroup(props: DropdownMenuRadioGroupProps) {
  return <DropdownMenuRadioGroupBase {...props} />
}

export default DropdownMenuRadioGroup

import type { ComponentProps } from "react"
import { CardTitle as CardTitleBase } from "@/v2-ui/card/base/CardBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CardTitleProps = ComponentProps<typeof CardTitleBase>

function CardTitle(props: CardTitleProps) {
  const { className, ...cardTitleBaseProps } = props
  return (
    <CardTitleBase
      className={cn("ui-card-title", className)}
      {...cardTitleBaseProps}
    />
  )
}

export default CardTitle

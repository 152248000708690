import type { ComponentProps } from "react"
import { DropdownMenuGroup as DropdownMenuBaseGroupBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"

export type DropdownMenuGroupProps = ComponentProps<typeof DropdownMenuBaseGroupBase>

function DropdownMenuGroup(props: DropdownMenuGroupProps) {
  return <DropdownMenuBaseGroupBase {...props} />
}

export default DropdownMenuGroup

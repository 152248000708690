import { useMutation, type MutationHookOptions } from "@apollo/client"
import mutation from "./currentUser.country.update.mutation.gql"

import type {
  CurrentUserCountryUpdateMutation,
  CurrentUserCountryUpdateMutationVariables
} from "./__types__/currentUser.country.update.mutation"

type Options = MutationHookOptions<
  CurrentUserCountryUpdateMutation,
  CurrentUserCountryUpdateMutationVariables
>

function useCurrentUserCountryUpdateMutation(options?: Options) {
  return useMutation(mutation, options)
}

export default useCurrentUserCountryUpdateMutation

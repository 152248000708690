import type { ComponentProps } from "react"
import { FormControl as FormControlBase } from "@/v2-ui/form/base/FormBase"
import cn from "@/v2-ui/utils/utils.cn"

export type FormControlProps = ComponentProps<typeof FormControlBase>

function FormControl(props: FormControlProps) {
  const { className, ...formBaseProps } = props

  return (
    <FormControlBase
      className={cn("ui-form-control", className)}
      {...formBaseProps}
    />
  )
}

export default FormControl

import type { ComponentProps } from "react"
import { TooltipProvider as TooltipProviderBase } from "@/v2-ui/tooltip/base/TooltipBase"

export type TooltipProviderProps = ComponentProps<typeof TooltipProviderBase>

function TooltipProvider(props: TooltipProviderProps) {
  const {
    delayDuration = 0,
    ...tooltipProviderBase
  } = props

  return (
    <TooltipProviderBase
    delayDuration={delayDuration}
      {...tooltipProviderBase}
    />
  )
}

export default TooltipProvider

import type { ComponentProps } from "react"
import { DropdownMenuShortcut as DropdownMenuBaseShortcutBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"
import cn from "@/v2-ui/utils/utils.cn"

export type DropdownMenuShortcutProps = ComponentProps<typeof DropdownMenuBaseShortcutBase>

function DropdownMenuShortcut(props: DropdownMenuShortcutProps) {
  const { className, ...dropdownMenuBaseShortcutBaseProps } = props

  return (
    <DropdownMenuBaseShortcutBase
      className={cn("ui-dropdown-menu-shortcut", className)}
      {...dropdownMenuBaseShortcutBaseProps}
    />
  )
}

export default DropdownMenuShortcut

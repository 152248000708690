import type { ComponentProps } from "react"
import { SheetDescription as SheetDescriptionBase }
  from "@/v2-ui/sheet/base/SheetBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SheetDescriptionProps = ComponentProps<typeof SheetDescriptionBase>

function SheetDescription(props: SheetDescriptionProps) {
  const { className, ...sheetDescriptionBaseProps } = props
  return (
    <SheetDescriptionBase
      className={cn("ui-sheet-description", className)}
      {...sheetDescriptionBaseProps}
    />
  )
}

export default SheetDescription

import type { ComponentProps } from "react"
import { CardContent as CardContentBase } from "@/v2-ui/card/base/CardBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CardContentProps = ComponentProps<typeof CardContentBase>

function CardContent(props: CardContentProps) {
  const { className, ...cardContentBaseProps } = props
  return (
    <CardContentBase
      className={cn("ui-card-content", className)}
      {...cardContentBaseProps}
    />
  )
}

export default CardContent

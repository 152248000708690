import type { ComponentProps } from "react"
import { SidebarProvider as SidebarProviderBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarProviderProps = ComponentProps<typeof SidebarProviderBase>

function SidebarProvider(props: SidebarProviderProps) {
  const { className, ...sidebarProviderBaseProps } = props
  return (
    <SidebarProviderBase
      className={cn("ui-sidebar-provider group/sidebar-wrapper", className)}
      {...sidebarProviderBaseProps}
    />
  )
}

export default SidebarProvider

import { forwardRef, type ComponentProps } from "react"
import { DropdownMenuTrigger as DropdownMenuTriggerBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"

export type DropdownMenuTriggerProps = ComponentProps<typeof DropdownMenuTriggerBase>

function DropdownMenuTrigger(props: DropdownMenuTriggerProps, ref) {
  const { asChild = true, ...dropdownMenuTriggerBase } = props
  return (
    <DropdownMenuTriggerBase
      ref={ref}
      asChild={asChild}
      {...dropdownMenuTriggerBase}
    />
  )
}

export default forwardRef(DropdownMenuTrigger)


import type { ComponentProps } from "react"
import { SidebarMenuItem as SidebarMenuItemBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarMenuItemProps = ComponentProps<typeof SidebarMenuItemBase>

function SidebarMenuItem(props: SidebarMenuItemProps) {
  const { className, ...sidebarMenuItemBaseProps } = props
  return (
    <SidebarMenuItemBase
      className={cn("ui-sidebar-menu-item group/menu-item", className)}
      {...sidebarMenuItemBaseProps}
    />
  )
}

export default SidebarMenuItem

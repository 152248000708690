import type { ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import type { CurrentUserFragment }
from "@/v2-console-shared/currentUser/__types__/currentUser.fragment"
import CurrentUserTenantPickerItem
  from "@/v2-console-ui/currentUser/tenant/CurrentUserTenantPickerItem"

type BaseProps = Omit<ComponentProps<"div">, "onChange">

export type CurrentUserMenuTenantPickerProps = BaseProps & {
  userTenants: CurrentUserFragment["UserTenants"],
  activeTenant: CurrentUserFragment["UserTenants"][0],
  onChange?(v: CurrentUserFragment["UserTenants"][0]): void,
  isMini?: boolean,
  isLoading?: boolean
}

function CurrentUserMenuTenantPicker(
  props: CurrentUserMenuTenantPickerProps
) {
  const {
    isLoading,
    userTenants,
    activeTenant,
    onChange,
    className,
    ...divProps
  } = props

  return (
    <div
      className={cn("flex flex-col w-full", className)}
      {...divProps}
    >
      <div className="pt-2 px-2 select-none">
        Bytt medlemskap
      </div>
      <div className="p-2">
        {userTenants.map((ut) => (
          <CurrentUserTenantPickerItem
            key={ut.TenantId}
            // isLoading={isLoading} // @note: looks more messy when loading
            userTenant={ut}
            isActive={ut.TenantId === activeTenant.TenantId}
            onClick={() => {
              if(ut.TenantId === activeTenant.TenantId) return
              onChange(ut)
            }}
          />
        )
        )}
      </div>
    </div>
  )
}

export default CurrentUserMenuTenantPicker

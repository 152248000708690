import cn from "@/v2-ui/utils/utils.cn"
import Frame, { type FrameFullscreenButtonProps } from "@/v2-ui/frame"

export type DialogFullscreenButtonProps = FrameFullscreenButtonProps

function DialogFullscreenButton(props: DialogFullscreenButtonProps) {
  const { className, ...frameFullscreenButtonProps } = props
  return (
    <Frame.FullscreenButton
      className={cn("dialog-fullscreen-button", className)}
      {...frameFullscreenButtonProps}
    />
  )
}

export default DialogFullscreenButton

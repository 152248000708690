import type { ReactNode } from "react"
import { createContext, useCallback, useContext, useMemo } from "react"
import {
  FALLBACK_TEXT,
  LOADING_TEXT
} from "@/v2-console-ui/text/text.constants"

// TextValue is fixed for all Text contexts
export type TextValue = {
  Name: string
  Description: string
}

// The base type for all Texts
export type TextsBase = Record<string, TextValue>

// Generic text type that specifies the keys and their corresponding TextValue
export type Texts<T extends TextsBase> = T

export type TextProviderValue<T extends TextsBase> = {
  texts: Texts<T>
  loading?: boolean
  error?: Error | null
  getText?: (key: keyof T) => TextValue
}

const TextContext = createContext<TextProviderValue<any> | undefined>(undefined)

export type TextProviderProps<T extends TextsBase> = {
  value: TextProviderValue<T>
  children: ReactNode
}

/**
 * Provides a context for accessing text values within the component tree.
 *
 * This component accepts a `value` prop, which is expected to contain a `texts` object
 * and a `loading` state. The `getText` function is defined using `useCallback` to
 * return a specific text based on the provided key, either returning a loading state text,
 * the actual text from `texts`, or a fallback text.
 *
 * @typeParam T - A generic extending `TextsBase` representing the shape of the text data.
 * @param props - The `TextProviderProps` including `value` and `children`.
 * @returns A `TextContext.Provider` wrapping the `children` components.
 */
function TextProvider<T extends TextsBase>(props: TextProviderProps<T>) {
  const { value, children } = props

  // Define the getText function here
  const getText = useCallback((key: keyof T): TextValue => {
    if(value.loading) return LOADING_TEXT
    return value.texts?.[key] || FALLBACK_TEXT
  }, [ value ])

  const contextValue = useMemo(() => ({
    ...value,
    getText
  }), [ value, getText ])

  return (
    <TextContext.Provider value={contextValue}>
      {children}
    </TextContext.Provider>
  )
}

/**
 * Custom hook to access the `TextContext` with proper typing.
 *
 * This hook retrieves the context value for text management and ensures that
 * it is being used within a `TextProvider`. If the context is not available,
 * it throws an error to enforce the provider usage.
 *
 * @typeParam T - A generic extending `TextsBase` representing the shape of the text data.
 * @throws Will throw an error if the hook is used outside of a `TextProvider`.
 * @returns The `TextProviderValue` containing the context value and functions.
 */
export function useText<T extends TextsBase>() {
  const context = useContext(TextContext)
  if(!context) {
    throw new Error("useText must be used within a TextProvider")
  }
  return context as TextProviderValue<T>
}

export default TextProvider

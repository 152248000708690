import { ASSETS_BASE_URL } from "@/v2-ui/constants"
import { ASSETS_FLAG_DIR } from "@/v2-ui/constants"

export function getAssetUrl<T extends string = string>(path: string) {
  return `${ASSETS_BASE_URL}/${path}` as T
}

export function mapAssetNamesToUrls(
  baseUrl: string,
  names: string[],
  ext = "svg"
) {
  const ns: Record<string, string> = {}
  for(const name of names) {
    ns[name] = getAssetUrl(`${baseUrl}/${name}.${ext}`)
  }
  return ns
}

export function getFlagAssetUrl<T extends string = string>(flagCode: string) {
  const flatPath = `/${ASSETS_FLAG_DIR}/${flagCode}.svg`
  return getAssetUrl(flatPath) as T
}

import { useState } from "react"
import CurrentUserMenuContainer
  from "@/v2-console-ui/currentUser/CurrentUserMenuContainer"
import CurrentUserProfileForm
  from "@/v2-console-ui/currentUser/profile/CurrentUserProfileForm"
import { useAppContext }
  from "@/v2-console/app/context/AppContextProvider"
import { useAppContextQueryIsLoading }
  from "@/v2-console/app/context/app.context.query"
import { useTenants }
  from "@/v2-console/currentUser/tenant/currentUser.tenant.utils"
import { useCurrentUserProfile }
  from "@/v2-console/currentUser/profile/currentUser.profile.utils"
import { useTenantChange }
  from "@/v2-console/currentUser/tenant/currentUser.tenant.utils"
import { logout } from "@/v2-console/auth/auth.instance"

export type CurrentUserMenuProps = {
  loading?: boolean
}

function CurrentUserMenu(props: CurrentUserMenuProps) {
  const { loading } = props
  const { currentUser } = useAppContext()
  const isLoading = useAppContextQueryIsLoading()
  const [ isUserFormShown, setIsUserFormShown ] = useState(false)

  const {
    activeTenant,
    userTenants
  } = useTenants()

  const {
    onTenantChange
    // tenantChangeIsLoading // @note: could use but makes the ui look messy
  } = useTenantChange()

  const formProps = useCurrentUserProfile()

  const onFormCancel = () => {
    setIsUserFormShown(false)
  }

  return (
    isUserFormShown
      ? <CurrentUserProfileForm
          {...formProps}
          onCancel={onFormCancel}
        />
      : <CurrentUserMenuContainer
          logout={logout}
          currentUser={currentUser}
          userTenants={userTenants}
          activeTenant={activeTenant}
          isLoading={loading || isLoading}
          onShowUserForm={() => setIsUserFormShown(true)}
          onTenantChange={onTenantChange}
        />
  )
}

export default CurrentUserMenu

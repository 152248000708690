import { useQuery, type QueryHookOptions } from "@apollo/client"
import { useLazyQuery, type LazyQueryHookOptions } from "@apollo/client"
import query from "./currentUser.language.query.gql"
import type {
  CurrentUserLanguageQuery,
  CurrentUserLanguageQueryVariables
} from "./__types__/currentUser.language.query"

type LazyOptions = LazyQueryHookOptions<
CurrentUserLanguageQuery,
CurrentUserLanguageQueryVariables
>

export function useCurrentUserLanguageLazyQuery(options?: LazyOptions) {
  return useLazyQuery<
    CurrentUserLanguageQuery,
    CurrentUserLanguageQueryVariables
  >(query, options)
}

type Options = QueryHookOptions<
CurrentUserLanguageQuery,
CurrentUserLanguageQueryVariables
>

function useCurrentUserLanguageQuery(options?: Options) {
  return useQuery<
    CurrentUserLanguageQuery,
    CurrentUserLanguageQueryVariables
  >(query, options)
}

export default useCurrentUserLanguageQuery

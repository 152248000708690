import { useCallback, useEffect, useState } from "react"
import { useApolloClient } from "@apollo/client"
import { useAppContext } from "@/v2-console/app/context/AppContextProvider"
import { useCurrentUserCountryLazyQuery }
  from "@/v2-console/currentUser/country/currentUser.country.query"
import useCurrentUserCountryUpdateMutation
  from "@/v2-console/currentUser/country/update/currentUser.country.update.mutation"
import type { CurrentUserCountryFragment }
  from "@/v2-console-shared/currentUser/country/__types__/currentUser.country.fragment"
import { trackCurrentUserCountryChanged }
  from "@/v2-console/currentUser/currentUser.tracking"

export const useCurrentUserCountry = () => {

  const [ activeCountry, setActiveCountry ] = useState<CurrentUserCountryFragment>()

  const client = useApolloClient()
  const { currentUser } = useAppContext()

  const [ getCountriesQuery, { data, error: queryError, loading: isQueryLoading }]
    = useCurrentUserCountryLazyQuery()

  const [ updateCountryMutation, { error: updateQueryError, loading: isUpdateQueryLoading }]
    = useCurrentUserCountryUpdateMutation()

  useEffect(() => {
    if(!currentUser) return
    getCountriesQuery()
  }, [ getCountriesQuery, currentUser ])

  useEffect(() => {
    if(!data?.getCountries || !currentUser) return
    const matchedCountry = data.getCountries.find(
      country => country.Code === currentUser.ActiveCountryCode
    )
    setActiveCountry(matchedCountry)
  }, [ currentUser, data?.getCountries ])

  const onCountryChange = useCallback((country: CurrentUserCountryFragment) => {
    updateCountryMutation({
      variables: {
        input: {
          countryCode: country.Code
        }
      },
      onCompleted: () => {
        // @note: we need to reset client cache in order to refetch
        // all queries  with the new country
        setActiveCountry(null)
        client.resetStore()
        // track country change
        trackCurrentUserCountryChanged(country.Code?.toString())
      }
    })
  }, [ updateCountryMutation, client ])

  const loading = !activeCountry || isQueryLoading || isUpdateQueryLoading
  const error = queryError || updateQueryError
  const countries = data?.getCountries || []

  return {
    activeCountry,
    loading,
    error,
    countries,
    onCountryChange
  }

}

import { track } from "@/v2-console-shared/analytics"

export function trackCurrentUserMenuShown() {
  track("CurrentUser_Menu_Shown", {
    module: "CurrentUser",
    source: "Menu",
    action: "Shown",
    issueId: 345,
    sprintNo: 5
  })
}

export function trackCurrentUserMenuActiveTenantChanged() {
  track("CurrentUser_Menu_ActiveTenantChanged", {
    module: "CurrentUser",
    source: "Menu",
    action: "ActiveTenantChanged",
    issueId: 0,
    sprintNo: 0
  })
}

export function trackCurrentUserLanguageChanged(languageCode: string) {
  track("CurrentUser_Language_Changed", {
    module: "CurrentUser",
    source: "Language",
    action: "Changed",
    value: languageCode,
    issueId: 224,
    sprintNo: 4
  })
}
export function trackCurrentUserCountryChanged(countryCode: string) {
  track("CurrentUser_Country_Changed", {
    module: "CurrentUser",
    source: "Country",
    action: "Changed",
    value: countryCode,
    issueId: 344,
    sprintNo: 5
  })
}

export function trackCurrentUserLanguageChangedWithUnsupportedArcGisLanguage(languageCode: string) {
  track("CurrentUser_Language_ChangedWithUnsupportedArcGisLanguage", {
    module: "CurrentUser",
    source: "Language",
    action: "ChangedWithUnsupportedArcGisLanguage",
    value: languageCode,
    issueId: 224,
    sprintNo: 4
  })
}

export function trackCurrentUserProfileUpdated() {
  track("CurrentUser_Profile_Updated", {
    module: "CurrentUser",
    source: "Profile",
    action: "Updated",
    issueId: 345,
    sprintNo: 5
  })
}

export function trackCurrentUserConfigUpdated() {
  track("CurrentUser_Configuration_Updated", {
    module: "CurrentUser",
    source: "Config",
    action: "Updated",
    issueId: 345,
    sprintNo: 6
  })
}

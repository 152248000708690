import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import type { Interval } from "@/v2-common/types"

export type PortalProps = {
  children: any,
  element: string | Element,
  key?: string,
  isAlwaysRendered?: boolean,
  isRetryEnabled?: boolean,
  fallbackElement?: string | Element
}

function Portal(props: PortalProps) {
  const {
    children,
    element,
    key,
    isAlwaysRendered,
    isRetryEnabled,
    fallbackElement
  } = props

  const [ elem, setElem ] = useState<Element>()

  useEffect(() => {
    if(!element) return

    let t: Interval
    let el: Element

    if(typeof element === "string") {
      el = document.querySelector(element)

      if(!el) {
        if(fallbackElement) {
          el = typeof fallbackElement === "string"
            ? document.querySelector(fallbackElement)
            : fallbackElement
        }

        if(!el && isRetryEnabled) {
          t = setInterval(() => {
            el = document.querySelector(element)
            if(el) clearInterval(t)
          }, 50)
        }
      }
    } else {
      el = element
    }

    if(el) setElem(el)

    return () => {
      if(t) clearInterval(t)
    }
  }, [
    element,
    isRetryEnabled,
    fallbackElement
  ])

  if(!elem) return null

  return element
    ? createPortal(children, elem, key)
    : isAlwaysRendered
      ? children
      : null
}

export default Portal

import type { ComponentProps } from "react"
import { SidebarRail as SidebarRailBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarRailProps = ComponentProps<typeof SidebarRailBase>

function SidebarRail(props: SidebarRailProps) {
  const { className, ...sidebarRailBaseProps } = props
  return (
    <SidebarRailBase
      className={cn("ui-sidebar-rail", className)}
      {...sidebarRailBaseProps}
    />
  )
}

export default SidebarRail

import type { ComponentProps } from "react"
import { Avatar as AvatarBase }
  from "@/v2-ui/avatar/base/AvatarBase"
import Skeleton from "@/v2-ui/skeleton"
import cn from "@/v2-ui/utils/utils.cn"

export type AvatarProps = ComponentProps<typeof AvatarBase> & {
  isLoading?: boolean
}

function Avatar(props: AvatarProps) {
  const { isLoading, className, ...avatarProps } = props

  if(isLoading) {
    return (
      <Skeleton
        className={cn("ui-avatar", className)}
      />
    )
  }

  return (
    <AvatarBase
      className={cn("ui-avatar", className)}
      {...avatarProps}
    />
  )
}

export default Avatar

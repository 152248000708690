import type { ComponentProps } from "react"
import { Sheet as SheetBase } from "@/v2-ui/sheet/base/SheetBase"

export type SheetProps = ComponentProps<typeof SheetBase>

function Sheet(props: SheetProps) {
  const { ...sheetBaseProps } = props
  return (
    <SheetBase
      {...sheetBaseProps}
    />
  )
}

export default Sheet

import { useMemo } from "react"
import type { CurrentUserCountryFragment }
  from "@/v2-console-shared/currentUser/country/__types__/currentUser.country.fragment"
import { getFlagAssetUrl } from "@/v2-ui/utils/utils.assets"
import CommandSimple, { type CommandSimpleItemProps }
  from "@/v2-console-ui/commandSimple"

export type CountrySelectorProps = {
  activeCountry?: CurrentUserCountryFragment,
  countries?: CurrentUserCountryFragment[],
  onCountryChange: (country: CurrentUserCountryFragment) => void,
  loading?: boolean,
  error?: Error,
}

const CountrySelector = (props: CountrySelectorProps) => {
  const {
    activeCountry,
    countries = [],
    onCountryChange,
    loading,
    error
  } = props

  const selectMenuData: CommandSimpleItemProps[] = useMemo(() =>
    countries.map((country) => ({
      value: country.Code,
      label: country.Name,
      icon: <img
        src={getFlagAssetUrl(country.Alpha2.toLowerCase())}
        alt=""
        className="object-cover h-4 w-6"
      />
    })), [ countries ])

  return (
    <CommandSimple
      items={selectMenuData}
      activeValue={activeCountry?.Code}
      heading="Country"
      loading={loading}
      searchPlaceholder="Søk etter land"
      emptyMessage="Ingen land funnet..."
      error={error}
      onSelectChange={(value) => {
        const country = countries.find((c) => c.Code === value)
        if(country) {
          onCountryChange(country)
        }
      }}
    />
  )
}

export default CountrySelector

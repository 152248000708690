import "./sheet.css"
import Sheet from "./Sheet"
import SheetContent from "./SheetContent"
import SheetDescription from "./SheetDescription"
import SheetFooter from "./SheetFooter"
import SheetHeader from "./SheetHeader"
import SheetOverlay from "./SheetOverlay"
import SheetTitle from "./SheetTitle"

export type { SheetProps } from "./Sheet"
export type { SheetContentProps } from "./SheetContent"
export type { SheetDescriptionProps } from "./SheetDescription"
export type { SheetHeaderProps } from "./SheetHeader"
export type { SheetOverlayProps } from "./SheetOverlay"
export type { SheetTitleProps } from "./SheetTitle"
export default Object.assign(Sheet, {
  Content: SheetContent,
  Description: SheetDescription,
  Footer: SheetFooter,
  Header: SheetHeader,
  Overlay: SheetOverlay,
  Title: SheetTitle
})

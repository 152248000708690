import type { ComponentProps, ReactNode } from "react"
import { TooltipButton } from "@/v2-ui/button"
import cn from "@/v2-ui/utils/utils.cn"

type BaseProps = Omit<ComponentProps<"div">, "children">

export type HeaderProps = BaseProps & {
  title: string,
  description?: string,
  tooltipContent?: string,
  tooltipPosition?: "beforeTitle" | "afterTitle",
  renderCloseButton?: ReactNode
}

function Header(props: HeaderProps) {
  const {
    title,
    description,
    tooltipContent,
    tooltipPosition = "afterTitle",
    renderCloseButton,
    className,
    ...divProps
  } = props

  return (
    <div
      className={cn("header", className)}
      {...divProps}
    >
      <div
        className="header--title"
      >
        {tooltipContent && tooltipPosition === "beforeTitle"
          ? <TooltipButton
              content={tooltipContent}
              iconSrc="info"
              variant="no-background"
              size="fit"
            />
          : null
        }
        {title}
        {tooltipContent && tooltipPosition === "afterTitle"
          ? <TooltipButton
              content={tooltipContent}
              iconSrc="info"
              variant="no-background"
              size="fit"
            />
          : null
        }
        {renderCloseButton
        ? renderCloseButton
          : null
        }
      </div>
      {description
        ? <div
            className="header--description"
          >
            {description}
          </div>
        : null
      }
    </div>
  )
}

export default Header

import { useEffect, useRef, type ComponentProps } from "react"
import { useIsMobile } from "@/v2-ui/utils/utils.mobile"
import { Popover as PopoverBase, PopoverAnchor } from "@/v2-ui/popover/base/PopoverBase"

type BaseProps = ComponentProps<typeof PopoverBase>
export type PopoverProps = BaseProps & {
  // defines a element anchor the override popover position
  // this will take presidence over the default mobile behavior
  anchor?: HTMLElement,
  // lets the cosumer skip the default mobile behavior
  skipDefaultAnchorOnMobile?: boolean
}

function Popover(props: PopoverProps) {
  const {
    children,
    anchor,
    skipDefaultAnchorOnMobile,
    ...popoverBaseProps
  } = props
  const isMobile = useIsMobile()

  // create a virtual ref for the anchor
  const virtualRef = useRef<HTMLElement>()
  useEffect(() => {
    if(!isMobile && !anchor) return

    // uses the anchor as the virtual ref if it is provided
    if(anchor) {
      virtualRef.current = anchor
      return
    }

    // create a referance to a virtual div that is positioned at the bottom of the viewport
    // this can be used to position the popover on mobile
    if(isMobile && !skipDefaultAnchorOnMobile) {
      virtualRef.current = {
        getBoundingClientRect: () =>
          new DOMRect(
            0,
            window.innerHeight, // y position at the bottom of the viewport
            0,
            0
          )
      } as unknown as HTMLDivElement
      return
    }

    return () => {
      virtualRef.current = null
    }

  }, [ isMobile, anchor, skipDefaultAnchorOnMobile ])

  const showAnchor = isMobile || anchor

  return (
    <PopoverBase
      {...popoverBaseProps}
    >
      {showAnchor
        ? <PopoverAnchor virtualRef={virtualRef} />
        : null
      }
      {children}
    </PopoverBase>
  )
}

export default Popover

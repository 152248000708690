import type { ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import Button from "@/v2-ui/button"
import Icon from "@/v2-ui/icon"
import { baseColors } from "@/v2-ui/theme/theme.baseColors"
import type { ThemeConfig } from "@/v2-ui/theme/theme.types"

const SELECTABLE_THEMES = [
  "zinc",
  "red",
  "rose",
  "orange",
  "green",
  "blue",
  "yellow",
  "violet"
]

type ThemeCustomizerThemePickerProps = ComponentProps<"div"> & {
  themeConfig: ThemeConfig,
  setThemeConfig: (themeConfig: ThemeConfig) => void
}
function ThemeCustomizerThemePicker(props: ThemeCustomizerThemePickerProps) {
  const {
    themeConfig,
    setThemeConfig,
    className,
    ...divProps
  } = props

  return (
    <div
      className={cn("flex gap-1", className)}
      {...divProps}
    >
      {SELECTABLE_THEMES.map((themeName) => {
        const t = baseColors.find((t) => t.name === themeName)
        const isActive = themeConfig.theme === t.name && !themeConfig.overriddendCssVars

        return (
          <Button
            variant="ghost"
            size="icon"
            key={t.name}
            onClick={() => {
              const {
                overriddendCssVars: _,
                ...themeConfigWithoutOverrides
              } = themeConfig
              setThemeConfig({
                ...themeConfigWithoutOverrides,
                theme: t.name
              })
            }}
            className={cn(
              "flex justify-center items-center"
            )}
            style={
              {
                "--theme-primary": `hsl(${t?.activeColor[themeConfig.mode === "dark" ? "dark" : "light"]
                  })`
              } as React.CSSProperties
            }
          >
            <span
              className={cn(
                "flex h-5 w-5 justify-center items-center rounded-full bg-[--theme-primary]"
              )}
            >
              {isActive && <Icon src="check" size={10} className="text-white" />}
            </span>
          </Button>
        )
      })}
    </div>
  )
}

export default ThemeCustomizerThemePicker

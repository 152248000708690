import { forwardRef } from "react"
import Sidebar from "@/v2-ui/sidebar"

type SidebarItemSkeletonProps = {
  showIcon?: boolean,
}
function SidebarItemSkeleton(
  props: SidebarItemSkeletonProps,
  ref
) {
  const { showIcon } = props

  return (
    <Sidebar.Menu.Button
      ref={ref}
      disabled
    >
      {showIcon
        ? <Sidebar.Menu.Skeleton
          randomWidth={false}
          showIcon={true}
          showLabel={false}
        />
        : null
      }
      <Sidebar.Menu.Skeleton
        randomWidth={false}
        showIcon={false}
        showLabel={true}
        className="w-full"
      />
    </Sidebar.Menu.Button>
  )
}

export default forwardRef(SidebarItemSkeleton)

import type { ComponentProps } from "react"
import { SidebarInset as SidebarInsetBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarInsetProps = ComponentProps<typeof SidebarInsetBase>

function SidebarInset(props: SidebarInsetProps) {
  const { className, ...sidebarInsetBaseProps } = props
  return (
    <SidebarInsetBase
      className={cn("ui-sidebar-inset", className)}
      {...sidebarInsetBaseProps}
    />
  )
}

export default SidebarInset

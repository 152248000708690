import type { ComponentProps } from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { Toast as ToastBase } from "@/v2-ui/toast/base/ToastBase"

const variants = cva(
  "ui-toast group",
  {
    variants: {
      variant: {
        default: "border bg-background text-foreground",
        destructive: "destructive group border-destructive bg-destructive text-destructive-foreground",
        info: "info group border-blue-400 bg-blue-400 text-info-foreground",
        success: "success group border-green-400 bg-green-400 text-success-foreground"
      },
      position: {
        top: "top-0",
        bottom: "bottom-0"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
)

export type ToastProps = ComponentProps<typeof ToastBase> &
  VariantProps<typeof variants>

function Toast(props: ToastProps) {
  const { className, variant, ...ToastProps } = props
  return (
    <ToastBase
      className={variants({ variant, className })}
      {...ToastProps}
    />
  )
}

export default Toast

import { useCallback } from "react"
import { useAppEvent } from "@/v2-console/app/context/AppContextProvider"
import { useNavigate } from "@/v2-console-shared/utils/utils.router"

export const TENANT_SWITCH_EVENT = "tenant-switch-event"

export function useOnTenantSwitch(cb: () => void) {
  useAppEvent(TENANT_SWITCH_EVENT, cb)
}

export function useNavigateOnTenantSwitch(url = "/map") {
  const navigate = useNavigate()
  useOnTenantSwitch(useCallback(() => {
    navigate(url)
  }, [
    navigate,
    url
  ]))
}

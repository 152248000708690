import "./command.css"
import Command from "./Command"
import CommandEmpty from "./CommandEmpty"
import CommandGroup from "./CommandGroup"
import CommandItem from "./CommandItem"
import CommandInput from "./CommandInput"
import CommandList from "./CommandList"
import CommandShortcut from "./CommandShortcut"
import CommandSeparator from "./CommandSeparator"

export type { CommandProps } from "./Command"
export type { CommandEmptyProps } from "./CommandEmpty"
export type { CommandGroupProps } from "./CommandGroup"
export type { CommandItemProps } from "./CommandItem"
export type { CommandSeparatorProps } from "./CommandSeparator"
export type { CommandShortcutProps } from "./CommandShortcut"
export type { CommandListProps } from "./CommandList"
export type { CommandInputProps } from "./CommandInput"

export default Object.assign(Command, {
  Empty: CommandEmpty,
  Group: CommandGroup,
  Item: CommandItem,
  Input: CommandInput,
  List: CommandList,
  Separator: CommandSeparator,
  Shortcut: CommandShortcut
})

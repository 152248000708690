import type { ComponentProps } from "react"
import { SheetOverlay as SheetOverlayBase }
  from "@/v2-ui/sheet/base/SheetBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SheetOverlayProps = ComponentProps<typeof SheetOverlayBase>

function SheetOverlay(props: SheetOverlayProps) {
  const { className, ...sheetOverlayBaseProps } = props
  return (
    <SheetOverlayBase
      className={cn("ui-sheet-overlay", className)}
      {...sheetOverlayBaseProps}
    />
  )
}

export default SheetOverlay

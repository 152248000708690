import type { ReactNode } from "react"
import { useMemo, createContext, useContext } from "react"
import useLocalStorage from "@/v2-ui/utils/utils.localStorage"
import SidebarBase from "@/v2-ui/sidebar"

type SidebarContextProps = {
  isMini?: boolean
  setIsMini?: (value: boolean) => void
}

const SidebarContext = createContext<SidebarContextProps>({
  isMini: false,
  setIsMini: () => { }
})

export function useSidebarContext() {
  const context = useContext(SidebarContext)
  if(!context) {
    throw new Error(
      "Sidebar compound components cannot be rendered outside the Sidebar component"
    )
  }
  return context
}

const SIDEBAR_LOCALSTORAGE_KEY = "sidebar-isMini"

type SidebarProviderProps = {
  children: ReactNode
}
function SidebarProvider(props: SidebarProviderProps) {
  const { children } = props
  const { value: isMini, setItem: setIsMini } = useLocalStorage(SIDEBAR_LOCALSTORAGE_KEY, false)

  const contextValue = useMemo(() => ({
    isMini,
    setIsMini
  }), [ isMini, setIsMini ])

  return (
    <SidebarContext.Provider value={contextValue}>
      <SidebarBase.Provider open={!isMini}>
        {children}
      </SidebarBase.Provider>
    </SidebarContext.Provider>
  )
}

export default SidebarProvider

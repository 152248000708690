import type { ComponentProps } from "react"
import { DropdownMenuLabel as DropdownMenuBaseLabelBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"
import cn from "@/v2-ui/utils/utils.cn"

export type DropdownMenuLabelProps = ComponentProps<typeof DropdownMenuBaseLabelBase>

function DropdownMenuLabel(props: DropdownMenuLabelProps) {
  const { className, ...dropdownMenuBaseLabelBaseProps } = props
  return (
    <DropdownMenuBaseLabelBase
      className={cn("ui-dropdown-menu-label", className)}
      {...dropdownMenuBaseLabelBaseProps}
    />
  )
}

export default DropdownMenuLabel

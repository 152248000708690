import Sidebar, { type SidebarItemProps }
  from "@/v2-layout-ui/sidebar"
import CountrySelector
  from "@/v2-console-ui/countrySelector/CountrySelector"
import { useCurrentUserCountry }
  from "@/v2-console/currentUser/country/currentUser.country.utils"

export type AppSidebarItemCountryProps = Partial<SidebarItemProps>

function AppSidebarItemCountry(props: AppSidebarItemCountryProps) {
  const { onClick, ...sidebarItemProps } = props

  const {
    activeCountry,
    loading,
    error,
    countries,
    onCountryChange
  } = useCurrentUserCountry()

  return (
    <Sidebar.Item
      onClick={onClick}
      iconSrc="globe"
      label={activeCountry?.Name}
      isLoading={loading}
      tooltip="Velg land"
      contentSize="md"
      popoverContent={() => (
        <CountrySelector
          activeCountry={activeCountry}
          countries={countries}
          onCountryChange={onCountryChange}
          loading={loading}
          error={error}
        />
      )}
      {...sidebarItemProps}
    />
  )
}

export default AppSidebarItemCountry

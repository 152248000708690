import type { QueryHookOptions, LazyQueryHookOptions } from "@apollo/client"
import { useQuery, useLazyQuery } from "@apollo/client"
import query from "@/v2-console/text/text.query.gql"
import type { TextQuery, TextQueryVariables }
  from "@/v2-console/text/__types__/text.query"

type LazyOptions = LazyQueryHookOptions<
  TextQuery,
  TextQueryVariables
>

export function useLazyTextQuery(options?: LazyOptions) {
  return useLazyQuery<TextQuery, TextQueryVariables>(query, options)
}

type Options = QueryHookOptions<
  TextQuery,
  TextQueryVariables
>

function useTextQuery(options: Options) {
  return useQuery<TextQuery, TextQueryVariables>(query, options)
}

export default useTextQuery

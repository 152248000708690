import type { ComponentProps } from "react"
import { SheetTitle as SheetTitleBase }
  from "@/v2-ui/sheet/base/SheetBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SheetTitleProps = ComponentProps<typeof SheetTitleBase>

function SheetTitle(props: SheetTitleProps) {
  const { className, ...sheetTitleBaseProps } = props
  return (
    <SheetTitleBase
      className={cn("ui-sheet-title", className)}
      {...sheetTitleBaseProps}
    />
  )
}

export default SheetTitle

import type { ComponentProps } from "react"
import { CardHeader as CardHeaderBase } from "@/v2-ui/card/base/CardBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CardHeaderProps = ComponentProps<typeof CardHeaderBase>

function CardHeader(props: CardHeaderProps) {
  const { className, ...cardHeaderBaseProps } = props
  return (
    <CardHeaderBase
      className={cn("ui-card-header", className)}
      {...cardHeaderBaseProps}
    />
  )
}

export default CardHeader

import { forwardRef, type ComponentProps } from "react"
import type { VariantProps } from "class-variance-authority"
import { cva } from "class-variance-authority"
import { SidebarMenuButton as SidebarMenuButtonBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

const sidebarMenuButtonVariants = cva(
  "peer/menu-button ui-sidebar-menu-button",
  {
    variants: {
      variant: {
        default: "hover:bg-sidebar-accent hover:text-sidebar-accent-foreground",
        outline: "bg-background shadow-[0_0_0_1px_hsl(var(--sidebar-border))] hover:bg-sidebar-accent hover:text-sidebar-accent-foreground hover:shadow-[0_0_0_1px_hsl(var(--sidebar-accent))]"
      },
      size: {
        default: "h-9",
        sm: "h-7 text-sm",
        lg: "h-12 group-data-[collapsible=icon]:!p-0"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
)

export type SidebarMenuButtonProps = ComponentProps<typeof SidebarMenuButtonBase>
  & VariantProps<typeof sidebarMenuButtonVariants> & {
  tooltip?: any
}

function SidebarMenuButton(props: SidebarMenuButtonProps, ref) {
  const {
    variant = "default",
    size = "default",
    className,
    ...sidebarMenuButtonBaseProps
  } = props

  return (
    <SidebarMenuButtonBase
      className={cn(sidebarMenuButtonVariants({ variant, size }), className)}
      data-size={size}
      ref={ref}
      {...sidebarMenuButtonBaseProps}
    />
  )
}

export default forwardRef(SidebarMenuButton)

import cn from "@/v2-ui/utils/utils.cn"
import Frame, { type FrameFooterProps } from "@/v2-ui/frame"

export type DialogFooterProps = FrameFooterProps

function DialogFooter(props: DialogFooterProps) {
  const { className, ...frameProps } = props
  return (
    <Frame.Footer
      className={cn("dialog-footer", className)}
      {...frameProps}
    />
  )
}

export default DialogFooter

import { forwardRef } from "react"
import type { TooltipButtonProps } from "@/v2-ui/button"
import { TooltipButton } from "@/v2-ui/button"

type BaseProps = Omit<TooltipButtonProps,
  "variant"
  | "size"
  | "iconSrc"
  | "iconSize"
>
export type CloseButtonProps = BaseProps

function CloseButton(props: CloseButtonProps, ref) {
  const {
    label = "Close",
    ...buttonProps
  } = props

  return (
    <TooltipButton
      variant="ghost"
      size="sm"
      iconSrc="cross"
      iconSize={12}
      label={label}
      ref={ref}
      {...buttonProps}
    />
  )
}

export default forwardRef(CloseButton)

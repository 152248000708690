import { forwardRef } from "react"
import Sidebar from "@/v2-ui/sidebar"

type SidebarCollapsibleSubItemSkeletonProps = {
  showIcon?: boolean,
}
function SidebarCollapsibleSubItemSkeleton(
  props: SidebarCollapsibleSubItemSkeletonProps,
  ref
) {
  const { showIcon } = props

  return (
    <Sidebar.Menu.Sub.Button
      className="pointer-events-none"
      ref={ref}
    >
      {
        showIcon
          ? <Sidebar.Menu.Skeleton
            randomWidth={false}
            showIcon={true}
            showLabel={false}
          />
          : null
      }
      <Sidebar.Menu.Skeleton
        randomWidth={false}
        showIcon={false}
        showLabel={true}
        className="w-full"
      />
    </Sidebar.Menu.Sub.Button>
  )
}

export default forwardRef(SidebarCollapsibleSubItemSkeleton)

import {
  type BadgeProps as BadgeBaseProps,
  Badge as BadgeBase
} from "@/v2-ui/badge/base/BadgeBase"
import cn from "@/v2-ui/utils/utils.cn"

export type BadgeProps = BadgeBaseProps

function Badge(props: BadgeProps) {
  const { className, ...badgeBaseProps } = props
  return (
    <BadgeBase
      className={cn("ui-badge", className)}
      {...badgeBaseProps}
    />
  )
}

export default Badge

import type { ComponentProps } from "react"
import { DropdownMenuSubTrigger as DropdownMenuBaseSubTriggerCore } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"
import cn from "@/v2-ui/utils/utils.cn"

export type DropdownMenuSubTriggerProps = ComponentProps<typeof DropdownMenuBaseSubTriggerCore>

function DropdownMenuSubTrigger(props: DropdownMenuSubTriggerProps) {
  const { className, ...dropdownMenuBaseSubTriggerCoreProps } = props
  return (
    <DropdownMenuBaseSubTriggerCore
      className={cn("ui-dropdown-menu-sub-trigger", className)}
      {...dropdownMenuBaseSubTriggerCoreProps}
    />
  )
}

export default DropdownMenuSubTrigger

import type { ComponentProps } from "react"
import { SidebarSeparator as SidebarSeparatorBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarSeparatorProps = ComponentProps<typeof SidebarSeparatorBase>

function SidebarSeparator(props: SidebarSeparatorProps) {
  const { className, ...sidebarSeparatorBaseProps } = props
  return (
    <SidebarSeparatorBase
      className={cn("ui-sidebar-separator", className)}
      {...sidebarSeparatorBaseProps}
    />
  )
}

export default SidebarSeparator

import type { ComponentProps } from "react"
import { SidebarGroupAction as SidebarGroupActionBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarGroupActionProps = ComponentProps<typeof SidebarGroupActionBase>

function SidebarGroupAction(props: SidebarGroupActionProps) {
  const { className, ...sidebarGroupActionBaseProps } = props
  return (
    <SidebarGroupActionBase
      className={cn("ui-sidebar-group-action", className)}
      {...sidebarGroupActionBaseProps}
    />
  )
}

export default SidebarGroupAction

import "./avatar.css"
import Avatar from "@/v2-ui/avatar/Avatar"
import AvatarImage from "@/v2-ui/avatar/AvatarImage"
import AvatarFallback from "@/v2-ui/avatar/AvatarFallback"

export type { AvatarProps } from "@/v2-ui/avatar/Avatar"
export type { AvatarImageProps } from "@/v2-ui/avatar/AvatarImage"
export type { AvatarFallbackProps } from "@/v2-ui/avatar/AvatarFallback"

export default Object.assign(Avatar, {
  Avatar,
  Image: AvatarImage,
  Fallback: AvatarFallback
})

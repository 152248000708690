import type { ComponentProps } from "react"
import { FormMessage as FormMessageBase } from "@/v2-ui/form/base/FormBase"
import cn from "@/v2-ui/utils/utils.cn"

export type FormMessageProps = ComponentProps<typeof FormMessageBase>

function FormMessage(props: FormMessageProps) {
  const { className, ...formBaseProps } = props

  return (
    <FormMessageBase
      className={cn("ui-form-message", className)}
      {...formBaseProps}
    />
  )
}

export default FormMessage

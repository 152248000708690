import type { ComponentProps } from "react"
import type { CustomIcons } from "@/v2-ui/icon/custom/icon.custom.types"
import IconCustomCompass from "@/v2-ui/icon/custom/compass/IconCustomCompass"

export type IconCustomProps = ComponentProps<"svg"> & {
  src: CustomIcons
}

function IconCustom(props: IconCustomProps) {
  const {
    src,
    ...svgProps
  } = props

  if(src === "compass") {
    return (
      <IconCustomCompass
        {...svgProps}
      />
    )
  }
}

export default IconCustom

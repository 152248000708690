import type { ComponentProps } from "react"
import { CardDescription as CardDescriptionBase } from "@/v2-ui/card/base/CardBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CardDescriptionProps = ComponentProps<typeof CardDescriptionBase>

function CardDescription(props: CardDescriptionProps) {
  const { className, ...cardDescriptionBaseProps } = props
  return (
    <CardDescriptionBase
      className={cn("ui-card-description", className)}
      {...cardDescriptionBaseProps}
    />
  )
}

export default CardDescription

import "./tooltip.css"
import Tooltip from "@/v2-ui/tooltip/Tooltip"
import TooltipTrigger from "@/v2-ui/tooltip/TooltipTrigger"
import TooltipContent from "@/v2-ui/tooltip/TooltipContent"
import TooltipProvider from "@/v2-ui/tooltip/TooltipProvider"

export type { TooltipProps } from "@/v2-ui/tooltip/Tooltip"
export type { TooltipTriggerProps } from "@/v2-ui/tooltip/TooltipTrigger"
export type { TooltipContentProps } from "@/v2-ui/tooltip/TooltipContent"
export type { TooltipProviderProps } from "@/v2-ui/tooltip/TooltipProvider"
export default Object.assign(Tooltip, {
  Trigger: TooltipTrigger,
  Content: TooltipContent,
  Provider: TooltipProvider
})

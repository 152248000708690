import type { ComponentProps } from "react"
import { ToastDescription as ToastDescriptionBase } from "@/v2-ui/toast/base/ToastBase"
import cn from "@/v2-ui/utils/utils.cn"

export type ToastDescriptionProps = ComponentProps<typeof ToastDescriptionBase>

function ToastDescription(props: ToastDescriptionProps) {
  const { className, ...toastDescriptionBaseProps } = props
  return (
    <ToastDescriptionBase
      className={cn("ui-toast-description", className)}
      {...toastDescriptionBaseProps}
    />
  )
}

export default ToastDescription

import Sidebar from "@/v2-ui/sidebar"

type SidebarItemFooterProps = {
  children: React.ReactNode
}
function SidebarFooter(props: SidebarItemFooterProps) {
  const { children } = props
  return (
    <Sidebar.Footer>
      <Sidebar.Menu>
        {children}
      </Sidebar.Menu>
    </Sidebar.Footer>
  )
}

export default SidebarFooter

import type { ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import Button from "@/v2-ui/button/Button"
import Separator from "@/v2-ui/separator"
import Popover from "@/v2-ui/popover"
import Card from "@/v2-ui/card"
import type { CurrentUserFragment }
  from "@/v2-console-shared/currentUser/__types__/currentUser.fragment"
import CurrentUserTenantPicker
  from "@/v2-console-ui/currentUser/tenant/CurrentUserTenantPicker"
import CurrentUserProfile
  from "@/v2-console-ui/currentUser/profile/CurrentUserProfile"
import { CloseButton } from "@/v2-layout-ui/buttons"
import Header from "@/v2-layout-ui/header"

export type CurrentUserMenuContainerProps = ComponentProps<"div"> & {
  logout(): void,
  isMini?: boolean,
  activeTenant: CurrentUserFragment["UserTenants"][0],
  onTenantChange?(v: CurrentUserFragment["UserTenants"][0]): void,
  onShowUserForm?(): void,
  isLoading?: boolean,
  userTenants?: CurrentUserFragment["UserTenants"],
  currentUser: CurrentUserFragment
}

function CurrentUserMenuContainer(props: CurrentUserMenuContainerProps) {
  const {
    className,
    logout,
    activeTenant,
    onShowUserForm,
    userTenants,
    onTenantChange,
    isLoading,
    currentUser
  } = props

  return (
    <Card
      className={cn("border-none shadow-none p-0", className)}
    >
      <Header
        className="p-3 px-2 relative"
        title="Profile"
        renderCloseButton={(
          <Popover.Close
            className="ml-auto"
            asChild
          >
            <CloseButton />
          </Popover.Close>
        )}
      />

      <Separator />

      <Card.Content className="p-0">
        <CurrentUserProfile
          isLoading={isLoading}
          currentUser={currentUser}
          onShowUserForm={onShowUserForm}
        />

        <Separator />

        <CurrentUserTenantPicker
          isLoading={isLoading}
          userTenants={userTenants}
          activeTenant={activeTenant}
          onChange={(v) => {
            onTenantChange?.(v)
          }}
        />
      </Card.Content>

      <Separator />

      <Card.Footer className="p-2">
        <Button
          variant="ghost"
          className="current-user-menu__logout w-full justify-start px-2 py-1.5 gap-1.5"
          iconSrc="power"
          onClick={() => {
            logout()
          }}
        >
          Logg ut
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default CurrentUserMenuContainer

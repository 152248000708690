import type { ComponentProps } from "react"
import { PopoverAnchor as PopoverAnchorBase } from "@/v2-ui/popover/base/PopoverBase"

type BaseProps = ComponentProps<typeof PopoverAnchorBase>
export type PopoverAnchorProps = BaseProps

function PopoverAnchor(props: PopoverAnchorProps) {
  const { ...popoverAnchorBaseProps } = props

  return (
    <PopoverAnchorBase
      {...popoverAnchorBaseProps}
    />
  )
}

export default PopoverAnchor

import { useId } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import Frame, { type FrameContainerProps } from "@/v2-ui/frame"
import useDraggable from "./dialog.draggable"

export type DialogContainerProps = FrameContainerProps & {
  hasBackdrop?: boolean,
  isAnchored?: boolean,
  isDraggable?: boolean,
  isResizable?: boolean
}

/**
 * This component is not exposed as part of the public API
 */
function DialogContainer(props: DialogContainerProps) {
  const {
    id,
    className,
    hasBackdrop = true,
    isAnchored = false,
    isDraggable = false,
    isResizable = false,
    onMouseUp,
    ...frameContainerProps
  } = props

  const rId = useId()
  const _id = id || rId

  useDraggable(_id, isDraggable, hasBackdrop)

  return (
    <Frame.Container
      role="dialog"
      id={_id}
      className={cn("dialog", className, {
        "dialog-has-backdrop": hasBackdrop,
        "dialog-has-no-backdrop": !hasBackdrop,
        "dialog-is-anchored": isAnchored,
        "dialog-is-draggable": isDraggable,
        "dialog-is-resizable": isResizable
      })}
      onMouseUp={isDraggable
        ? null
        : onMouseUp
      }
      {...frameContainerProps}
    />
  )
}

export default DialogContainer

import type { ComponentProps } from "react"
import { SheetFooter as SheetFooterBase }
  from "@/v2-ui/sheet/base/SheetBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SheetFooterProps = ComponentProps<typeof SheetFooterBase>

function SheetFooter(props: SheetFooterProps) {
  const { className, ...sheetFooterBaseProps } = props
  return (
    <SheetFooterBase
      className={cn("ui-sheet-footer", className)}
      {...sheetFooterBaseProps}
    />
  )
}

export default SheetFooter

import { forwardRef, type ComponentProps } from "react"
import { PopoverClose as PopoverCloseBase } from "@/v2-ui/popover/base/PopoverBase"

type BaseProps = ComponentProps<typeof PopoverCloseBase>
export type PopoverCloseProps = BaseProps

function PopoverClose(props: PopoverCloseProps, ref) {
  const { ...popoverCloseBaseProps } = props

  return (
    <PopoverCloseBase
      ref={ref}
      {...popoverCloseBaseProps}
    />
  )
}

export default forwardRef(PopoverClose)

import type { ComponentProps } from "react"
import { FormField as FormFieldBase } from "@/v2-ui/form/base/FormBase"

export type FormFieldProps = ComponentProps<typeof FormFieldBase>

function FormField(props: FormFieldProps) {
  const { ...formBaseProps } = props

  return (
    <FormFieldBase
      {...formBaseProps}
    />
  )
}

export default FormField

import type { ComponentProps } from "react"
import { Separator as SeparatorBase } from "@/v2-ui/separator/base/SeparatorBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SeparatorProps = ComponentProps<typeof SeparatorBase>

function Separator(props: SeparatorProps) {
  const { className, ...skeletonBaseProps } = props

  return (
    <SeparatorBase
      className={cn("ui-separator", className)}
      {...skeletonBaseProps}
    />
  )
}

export default Separator

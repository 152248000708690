import { type ReactNode, useCallback } from "react"
import Sidebar, { type SidebarItemProps }
  from "@/v2-layout-ui/sidebar"
import { useText }
  from "@/v2-console-ui/text/text.context"
import { trackAppThemeConfigChangedByUser }
  from "@/v2-console/app/app.tracking"
import ThemeCustomizer
  from "@/v2-console-ui/themeCustomizer/ThemeCustomizer"

export type ThemeCustomizerProps = {
  children?: ReactNode
}

export type AppSidebarItemThemeProps = Partial<SidebarItemProps>

function AppSidebarItemTheme(props: AppSidebarItemThemeProps) {
  const { ...sidebarItemProps } = props
  const { getText } = useText()

  const onThemeChanged = useCallback((field: string, value: string | number) => {
    trackAppThemeConfigChangedByUser(field, value)
  }, [])

  return (
    <Sidebar.Item
      iconSrc="paint-brush"
      tooltip={getText("UI_Theme_Root").Description}
      label={getText("UI_Theme_Root").Name}
      popoverContent={() => (
        <ThemeCustomizer
          onThemeChange={onThemeChanged}
        />
      )}
      {...sidebarItemProps}
    />
  )
}

export default AppSidebarItemTheme

import type { ComponentProps } from "react"

export type StateErrorNotFoundProps = ComponentProps<"svg">

function StateErrorNotFound(props: StateErrorNotFoundProps) {
  return (
    <svg
      viewBox="0 0 160 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1226_1055)">
        <path d="M56.3316 5.63356V5.6146C56.3305 5.61776 56.3295 5.62092 56.3289 5.62408C56.3279 5.62092 56.3268 5.61776 56.3263 5.6146V5.63356C56.0766 6.53364 55.4995 7.17722 54.5948 7.56432C55.4995 7.95142 56.0766 8.59501 56.3263 9.49508V9.51404C56.3273 9.51088 56.3284 9.50772 56.3289 9.50456C56.33 9.50772 56.331 9.51088 56.3316 9.51404V9.49508C56.5812 8.59501 57.1584 7.95142 58.0631 7.56432C57.1584 7.17722 56.5812 6.53364 56.3316 5.63356ZM129.836 2.83538V2.79694C129.834 2.80378 129.832 2.80958 129.83 2.81642C129.829 2.80958 129.827 2.80378 129.825 2.79694V2.83538C129.326 4.635 128.171 5.9227 126.362 6.6969C128.171 7.4711 129.325 8.7588 129.825 10.5584V10.5969C129.827 10.59 129.829 10.5842 129.83 10.5774C129.832 10.5842 129.834 10.59 129.836 10.5969V10.5584C130.335 8.7588 131.489 7.4711 133.299 6.6969C131.489 5.9227 130.335 4.635 129.836 2.83538ZM19.902 14.2346V14.1961C19.9004 14.203 19.8983 14.2087 19.8967 14.2156C19.8951 14.2087 19.893 14.203 19.8914 14.1961V14.2346C19.3922 16.0342 18.2378 17.3219 16.4284 18.0961C18.2378 18.8703 19.3916 20.158 19.8914 21.9576V21.996C19.893 21.9892 19.8951 21.9834 19.8967 21.9766C19.8983 21.9834 19.9004 21.9892 19.902 21.996V21.9576C20.4013 20.158 21.5556 18.8703 23.365 18.0961C21.5556 17.3219 20.4018 16.0342 19.902 14.2346Z" fill="#BABABA" />
        <path d="M90.2722 135.241C92.662 130.069 93.9955 124.315 93.9955 118.252C93.9955 95.7432 75.6331 77.4963 52.982 77.4963C30.3308 77.4963 11.9684 95.7432 11.9684 118.252C11.9684 124.315 13.3019 130.069 15.6917 135.241H90.2717H90.2722Z" fill="#E2E2E2" />
        <path d="M87.326 95.9666C83.7893 96.9435 78.6425 97.6477 71.4175 97.1605C57.2395 96.2041 40.2524 88.1066 26.9028 86.7957C18.6152 93.5887 13.0581 103.553 12.1132 114.82C16.139 111.484 22.0374 107.883 30.1909 105.794C48.3599 101.137 68.617 106.459 79.3352 105.794C83.8979 105.51 87.8698 104.186 90.9273 102.758C89.9399 100.372 88.731 98.1001 87.326 95.9666ZM83.6345 122.054C76.9163 121.758 53.2634 109.722 34.0361 114.399C23.2882 117.013 16.3171 120.523 12.2578 123.111C12.7698 127.393 13.9501 131.472 15.6922 135.241H90.2723C92.5963 130.212 93.9213 124.633 93.9923 118.753C90.8261 120.517 86.9036 122.198 83.6345 122.054Z" fill="#CECECE" />
        <path d="M29.0169 88.8655C34.0542 88.8655 38.1377 84.8077 38.1377 79.8021C38.1377 74.7965 34.0542 70.7387 29.0169 70.7387C23.9797 70.7387 19.8962 74.7965 19.8962 79.8021C19.8962 84.8077 23.9797 88.8655 29.0169 88.8655Z" fill="#BABABA" />
        <path d="M125.219 105.604L138.227 100.457C140.127 99.7054 141.754 98.3982 142.892 96.7087L152.007 83.1744C152.66 82.2043 152.271 80.8823 151.194 80.4152L143.725 77.1735C143 76.8586 142.154 77.0277 141.608 77.5959L135.367 84.0834L126.34 85.5175L125.219 105.605V105.604Z" className="fill-primary" />
        <path d="M125.219 105.867C125.165 105.867 125.11 105.851 125.065 105.818C124.991 105.765 124.95 105.679 124.955 105.59L126.075 85.5027C126.082 85.379 126.175 85.2768 126.298 85.2573L135.237 83.8369L141.416 77.4137C142.037 76.768 143.008 76.5747 143.831 76.9318L151.3 80.1734C151.885 80.4273 152.324 80.9223 152.503 81.5317C152.682 82.141 152.582 82.793 152.227 83.3202L143.112 96.8545C141.939 98.5962 140.284 99.9266 138.325 100.701L125.317 105.848C125.286 105.861 125.253 105.866 125.219 105.866V105.867ZM126.593 85.7434L125.507 105.206L138.129 100.213C139.988 99.4768 141.559 98.2144 142.672 96.5617L151.786 83.0274C152.054 82.6298 152.13 82.1384 151.995 81.6797C151.86 81.2204 151.529 80.8475 151.088 80.6558L143.619 77.4142C142.999 77.1451 142.267 77.291 141.799 77.7771L135.559 84.264C135.519 84.3056 135.466 84.333 135.409 84.342L126.593 85.7429V85.7434Z" fill="black" />
        <path d="M93.4724 91.8243L88.4326 78.8446C87.6959 76.9476 87.5555 74.8736 88.0298 72.8959L91.8278 57.0538C92.1002 55.9178 93.3399 55.3037 94.4169 55.7708L101.886 59.0125C102.611 59.3274 103.061 60.0584 103.013 60.8432L102.465 69.8012L107.54 77.3568L93.4724 91.8243Z" className="fill-primary" />
        <path d="M93.4724 92.0877C93.4549 92.0877 93.4374 92.0861 93.4199 92.0824C93.3314 92.0645 93.2578 92.0029 93.2254 91.9192L88.1857 78.9394C87.4267 76.9845 87.2836 74.8736 87.7723 72.8349L91.5702 56.9927C91.7181 56.3755 92.131 55.8588 92.7029 55.5755C93.2742 55.2921 93.9377 55.2753 94.5234 55.5296L101.992 58.7713C102.816 59.1289 103.333 59.9679 103.278 60.8595L102.735 69.7291L107.761 77.2104C107.831 77.3136 107.818 77.4506 107.732 77.5396L93.6637 92.0071C93.6134 92.0587 93.5445 92.0877 93.4729 92.0877H93.4724ZM93.6616 55.8772C93.4141 55.8772 93.1666 55.9341 92.9387 56.0468C92.5078 56.2601 92.1967 56.6493 92.0854 57.1149L88.2874 72.957C87.8237 74.8915 87.9593 76.8944 88.6796 78.7499L93.5699 91.3451L107.201 77.3268L102.244 69.9482C102.212 69.9003 102.197 69.8434 102.2 69.786L102.748 60.8279C102.79 60.1559 102.4 59.5239 101.78 59.2542L94.3109 56.0126C94.1031 55.9225 93.8826 55.8778 93.6621 55.8778L93.6616 55.8772Z" fill="black" />
        <path d="M106.154 135.241C107.384 134.061 108.657 132.745 109.995 131.277C119.779 120.542 130.597 100.244 133.795 78.3965L120.763 72.7401L107.731 67.0837C93.7755 84.2625 86.1419 105.943 84.8657 120.371C84.3203 126.534 84.4279 131.242 85.383 135.242L106.154 135.241Z" fill="white" />
        <path d="M106.154 135.505H85.383C85.26 135.505 85.1535 135.421 85.1249 135.302C84.1931 131.4 84.0315 126.788 84.6012 120.347C85.2743 112.743 87.6525 103.62 91.2978 94.6594C95.4387 84.4805 101.05 74.8878 107.525 66.9178C107.6 66.8256 107.728 66.7945 107.837 66.8419L133.901 78.1547C134.01 78.2021 134.075 78.3164 134.058 78.4339C132.574 88.5701 129.305 99.1798 124.605 109.116C120.467 117.863 115.348 125.796 110.191 131.454C108.816 132.962 107.556 134.263 106.338 135.43C106.289 135.478 106.223 135.504 106.154 135.504L106.154 135.505ZM85.5934 134.978H106.047C107.232 133.838 108.46 132.568 109.798 131.101C114.92 125.48 120.008 117.593 124.125 108.892C128.773 99.0671 132.014 88.5822 133.504 78.5566L107.81 67.4044C101.422 75.3033 95.8839 84.7918 91.7896 94.8564C88.1634 103.77 85.798 112.839 85.1297 120.393C84.5747 126.663 84.7173 131.175 85.5934 134.977V134.978Z" fill="black" />
        <path d="M102.892 123.592C108.557 123.592 113.149 119.028 113.149 113.399C113.149 107.769 108.557 103.206 102.892 103.206C97.2264 103.206 92.6339 107.769 92.6339 113.399C92.6339 119.028 97.2264 123.592 102.892 123.592Z" fill="white" />
        <path d="M102.888 123.861C101.458 123.861 100.03 123.567 98.6791 122.981C93.3621 120.673 90.9257 114.497 93.2482 109.213C95.5707 103.93 101.786 101.509 107.103 103.817C109.679 104.935 111.662 106.982 112.688 109.582C113.714 112.183 113.659 115.025 112.534 117.584C111.409 120.144 109.349 122.115 106.732 123.134C105.487 123.619 104.187 123.861 102.889 123.861H102.888ZM102.894 103.464C101.661 103.464 100.427 103.693 99.2441 104.154C96.7589 105.122 94.8022 106.994 93.7337 109.424C92.6652 111.855 92.6133 114.554 93.5874 117.023C94.5615 119.493 96.4452 121.437 98.8911 122.499C101.337 123.561 104.053 123.612 106.538 122.644C109.023 121.676 110.98 119.805 112.048 117.374C113.117 114.943 113.169 112.245 112.195 109.776C111.22 107.306 109.337 105.362 106.891 104.3C105.609 103.743 104.252 103.465 102.894 103.465V103.464Z" fill="black" fillOpacity="0.2" />
        <path d="M102.892 119.88C106.494 119.88 109.414 116.978 109.414 113.399C109.414 109.819 106.494 106.917 102.892 106.917C99.2891 106.917 96.3688 109.819 96.3688 113.399C96.3688 116.978 99.2891 119.88 102.892 119.88Z" fill="white" />
        <path d="M102.889 120.148C101.967 120.148 101.046 119.958 100.174 119.58C98.5127 118.859 97.2333 117.538 96.5713 115.861C95.9093 114.183 95.9448 112.35 96.6704 110.699C98.1687 107.29 102.178 105.729 105.608 107.218C109.038 108.707 110.609 112.691 109.111 116.099C108.386 117.75 107.056 119.021 105.368 119.679C104.565 119.992 103.726 120.148 102.889 120.148H102.889ZM102.9 107.18C100.486 107.18 98.1825 108.575 97.1564 110.91C96.4876 112.432 96.4547 114.122 97.0647 115.668C97.6748 117.214 98.8545 118.433 100.386 119.097C101.918 119.762 103.619 119.795 105.175 119.188C106.731 118.582 107.957 117.41 108.626 115.888C110.007 112.746 108.558 109.072 105.396 107.7C104.583 107.347 103.735 107.18 102.9 107.18L102.9 107.18Z" fill="black" fillOpacity="0.2" />
        <path d="M115.376 56.5056L136.409 65.6348C137.571 66.1388 138.009 67.5508 137.335 68.6173L131.723 77.4969L109.804 67.9832L112.546 57.8575C112.875 56.642 114.215 56.0016 115.376 56.5056Z" fill="#393F4F" />
        <path d="M131.723 77.7597C131.687 77.7597 131.651 77.7523 131.617 77.7376L109.698 68.2239C109.578 68.1718 109.514 68.0401 109.548 67.9142L112.29 57.7885C112.467 57.1349 112.92 56.5919 113.534 56.2986C114.148 56.0052 114.858 55.9926 115.482 56.2633L136.515 65.3925C137.14 65.6632 137.612 66.1899 137.813 66.8367C138.014 67.4834 137.921 68.1833 137.559 68.7564L131.947 77.636C131.897 77.715 131.811 77.7592 131.722 77.7592L131.723 77.7597ZM110.119 67.832L131.62 77.1641L137.11 68.4762C137.391 68.0327 137.462 67.4918 137.307 66.992C137.152 66.4917 136.786 66.0851 136.303 65.8755L115.27 56.7462C114.787 56.5366 114.238 56.5466 113.764 56.7736C113.289 57.0001 112.939 57.4204 112.802 57.9255L110.119 67.832Z" fill="black" />
        <path d="M109.355 98.7073L109.356 98.7075C110.448 99.1819 111.721 98.6862 112.199 97.6003L123.932 70.9125C124.409 69.8266 123.911 68.5617 122.818 68.0873L122.817 68.0871C121.725 67.6127 120.452 68.1084 119.974 69.1943L108.241 95.8821C107.763 96.968 108.262 98.2328 109.355 98.7073Z" className="fill-primary" />
        <path d="M110.21 99.1581C109.88 99.1581 109.551 99.0902 109.24 98.9554C108.015 98.4234 107.453 97.0004 107.989 95.7833L119.72 69.0949C119.979 68.5051 120.454 68.0511 121.057 67.8162C121.66 67.5813 122.319 67.5939 122.912 67.8515C123.505 68.109 123.962 68.5809 124.199 69.1803C124.435 69.7796 124.422 70.4343 124.163 71.0236L112.432 97.7119C112.173 98.3018 111.698 98.7558 111.095 98.9907C110.808 99.1023 110.509 99.1581 110.209 99.1581H110.21ZM121.942 68.1759C121.708 68.1759 121.474 68.2196 121.25 68.3065C120.779 68.4903 120.408 68.8448 120.206 69.3056L108.475 95.9939C108.056 96.9451 108.495 98.0574 109.452 98.4724C110.41 98.888 111.529 98.4519 111.947 97.5007L123.678 70.8124C123.881 70.3516 123.891 69.8402 123.706 69.372C123.521 68.9038 123.164 68.5351 122.7 68.3339C122.457 68.2286 122.2 68.1754 121.942 68.1754L121.942 68.1759Z" fill="black" />
        <path d="M106.154 135.241C106.538 134.873 106.926 134.491 107.319 134.096C101.117 128.046 93.5423 124.798 84.597 124.352C84.4126 128.635 84.6442 132.15 85.3824 135.241H106.154V135.241Z" className="fill-primary" />
        <path d="M106.154 135.504H85.3825C85.2595 135.504 85.153 135.421 85.1243 135.302C84.3935 132.241 84.1417 128.758 84.332 124.341C84.3352 124.271 84.3659 124.205 84.4184 124.158C84.4703 124.111 84.5398 124.086 84.6102 124.09C89.094 124.313 93.2932 125.252 97.0917 126.881C100.891 128.51 104.395 130.875 107.505 133.908C107.555 133.957 107.584 134.024 107.584 134.094C107.584 134.164 107.557 134.232 107.508 134.281C107.114 134.678 106.72 135.065 106.338 135.431C106.288 135.478 106.222 135.504 106.154 135.504ZM85.5929 134.978H106.047C106.341 134.695 106.641 134.401 106.943 134.099C103.927 131.2 100.544 128.934 96.8819 127.364C93.2169 125.792 89.1709 124.873 84.8509 124.629C84.6876 128.774 84.9245 132.075 85.5929 134.977V134.978Z" fill="black" />
        <path d="M148.43 135.505H7.62883C7.48255 135.505 7.36383 135.387 7.36383 135.241C7.36383 135.096 7.48255 134.978 7.62883 134.978H148.43C148.576 134.978 148.695 135.096 148.695 135.241C148.695 135.387 148.576 135.505 148.43 135.505H148.43Z" fill="#BABABA" />
        <path d="M28.9712 44.1065V35.3081L43.143 15.096H54.5092V34.975H57.9334V44.1057H54.5092V49.3372H43.8559V44.1057H28.9704L28.9712 44.1065ZM44.7124 27.1282L39.5761 34.975H44.7124V27.1282ZM68.5624 18.5209C70.8931 15.3822 74.4834 13.8129 79.3342 13.8129C84.185 13.8129 87.7356 15.3908 90.0818 18.5451C92.4281 21.7002 93.6012 26.0043 93.6012 31.4572C93.6012 36.9102 92.4281 41.2626 90.0818 44.4169C87.7356 47.572 84.1531 49.1492 79.3342 49.1492C74.5154 49.1492 70.8931 47.572 68.5624 44.4169C66.2318 41.2626 65.0672 36.943 65.0672 31.4572C65.0672 25.9715 66.2326 21.6604 68.5624 18.5217V18.5209ZM82.4254 25.7258C81.9176 24.4419 80.8872 23.8 79.3342 23.8C77.7812 23.8 76.7025 24.4419 76.1955 25.7258C75.6877 27.0097 75.435 28.9207 75.435 31.4565C75.435 33.9922 75.6885 35.9508 76.1955 37.2347C76.7025 38.5186 77.7485 39.1605 79.3342 39.1605C80.92 39.1605 81.9176 38.5186 82.4254 37.2347C82.9324 35.9508 83.1859 34.0242 83.1859 31.4565C83.1859 28.8887 82.9316 27.0097 82.4254 25.7258ZM101.067 44.1065V35.3081L115.239 15.096H126.605V34.975H130.03V44.1057H126.605V49.3372H115.952V44.1057H101.067L101.067 44.1065ZM116.809 27.1282L111.672 34.975H116.809V27.1282Z" fill="#BABABA" />
      </g>
      <defs>
        <clipPath id="clip0_1226_1055">
          <rect width="159" height="135" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StateErrorNotFound

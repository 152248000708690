import type { ComponentProps } from "react"
import { Sidebar as SidebarBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarProps = ComponentProps<typeof SidebarBase>

function Sidebar(props: SidebarProps) {
  const {
    className,
    collapsible = "offcanvas",
    side = "left",
    variant = "sidebar",
    ...sidebarBaseProps
  } = props

  return (
    <SidebarBase
      className={cn("ui-sidebar", className, {
        "collapsible-none": collapsible === "none",
        "collapsible-offcanvas": collapsible === "offcanvas",
        "collapsible-icon": collapsible === "icon",
        "side-left": side === "left",
        "side-right": side === "right",
        "variant-floating": variant === "floating",
        "variant-inset": variant === "inset",
        "variant-sidebar": variant === "sidebar"
      })}
      collapsible={collapsible}
      side={side}
      variant={variant}
      {...sidebarBaseProps}
    />
  )
}

export default Sidebar

import cn from "@/v2-ui/utils/utils.cn"
import Frame, { type FrameHeaderProps } from "@/v2-ui/frame"
import Separator from "@/v2-ui/separator"

export type DialogHeaderProps = FrameHeaderProps

function DialogHeader(props: DialogHeaderProps) {
  const { className, ...frameHeaderProps } = props
  return (
    <>
      <Frame.Header
        className={cn("dialog-header", className)}
        {...frameHeaderProps}
      />
      <Separator />
    </>
  )
}

export default DialogHeader

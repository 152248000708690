import "./dropdownMenu.css"
import DropdownMenu from "./DropdownMenu"
import DropdownMenuTrigger from "./DropdownMenuTrigger"
import DropdownMenuContent from "./DropdownMenuContent"
import DropdownMenuItem from "./DropdownMenuItem"
import DropdownMenuCheckboxItem from "./DropdownMenuCheckboxItem"
import DropdownMenuRadioItem from "./DropdownMenuRadioItem"
import DropdownMenuLabel from "./DropdownMenuLabel"
import DropdownMenuSeparator from "./DropdownMenuSeparator"
import DropdownMenuShortcut from "./DropdownMenuShortcut"
import DropdownMenuGroup from "./DropdownMenuGroup"
import DropdownMenuPortal from "./DropdownMenuPortal"
import DropdownMenuSub from "./DropdownMenuSub"
import DropdownMenuSubContent from "./DropdownMenuSubContent"
import DropdownMenuSubTrigger from "./DropdownMenuSubTrigger"
import DropdownMenuRadioGroup from "./DropdownMenuRadioGroup"

export type { DropdownMenuProps } from "./DropdownMenu"
export type { DropdownMenuTriggerProps } from "./DropdownMenuTrigger"
export type { DropdownMenuContentProps } from "./DropdownMenuContent"
export type { DropdownMenuItemProps } from "./DropdownMenuItem"
export type { DropdownMenuCheckboxItemProps } from "./DropdownMenuCheckboxItem"
export type { DropdownMenuRadioItemProps } from "./DropdownMenuRadioItem"
export type { DropdownMenuLabelProps } from "./DropdownMenuLabel"
export type { DropdownMenuSeparatorProps } from "./DropdownMenuSeparator"
export type { DropdownMenuShortcutProps } from "./DropdownMenuShortcut"
export type { DropdownMenuGroupProps } from "./DropdownMenuGroup"
export type { DropdownMenuPortalProps } from "./DropdownMenuPortal"
export type { DropdownMenuSubProps } from "./DropdownMenuSub"
export type { DropdownMenuSubContentProps } from "./DropdownMenuSubContent"
export type { DropdownMenuSubTriggerProps } from "./DropdownMenuSubTrigger"
export type { DropdownMenuRadioGroupProps } from "./DropdownMenuRadioGroup"

export default Object.assign(DropdownMenu, {
  Trigger: DropdownMenuTrigger,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
  CheckboxItem: DropdownMenuCheckboxItem,
  RadioItem: DropdownMenuRadioItem,
  Label: DropdownMenuLabel,
  Separator: DropdownMenuSeparator,
  Shortcut: DropdownMenuShortcut,
  Group: DropdownMenuGroup,
  Portal: DropdownMenuPortal,
  Sub: DropdownMenuSub,
  SubContent: DropdownMenuSubContent,
  SubTrigger: DropdownMenuSubTrigger,
  RadioGroup: DropdownMenuRadioGroup
})

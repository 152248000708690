import type { ComponentProps } from "react"
import { CommandShortcut as CommandShortcutBase } from "@/v2-ui/command/base/CommandBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CommandShortcutProps = ComponentProps<typeof CommandShortcutBase>

function CommandShortcut(props: CommandShortcutProps) {
  const { className, ...commandShortcutBaseProps } = props
  return (
    <CommandShortcutBase
      className={cn("ui-command-shortcut", className)}
      {...commandShortcutBaseProps}
    />
  )
}

export default CommandShortcut

import cn from "@/v2-ui/utils/utils.cn"
import { TooltipButton, type TooltipButtonProps } from "@/v2-ui/button"
import { useFrameContext } from "@/v2-ui/frame/frame.context"

export type FrameCloseButtonProps = TooltipButtonProps

function FrameCloseButton(props: FrameCloseButtonProps) {
  const { className, onClick, variant = "ghost", ...tooltipButtonProps } = props
  const { onHide } = useFrameContext()
  return (
    <TooltipButton
      variant={variant}
      tooltipTriggerClassName={cn("frame-close-button", className)}
      iconSrc="cross"
      size="sm"
      iconSize={12}
      content="Lukk"
      onClick={(e) => {
        if(onClick) onClick(e)
        onHide()
      }}
      {...tooltipButtonProps}
    />
  )
}

export default FrameCloseButton

import { useEffect, useMemo, useState } from "react"
import type { VirtualElement } from "@popperjs/core"
import type { Timeout } from "@/v2-common/types"
import { logError } from "@/v2-ui/utils/utils.log"

export function useClassNameToggle(
  isActive: boolean,
  className: string,
  selectors: string
) {
  useEffect(() => {
    if(!isActive || !className || !selectors) return
    const el = document.querySelector(selectors)
    if(!el) return
    el.classList.add(className)
    return () => {
      el.classList.remove(className)
    }
  }, [
    isActive,
    className,
    selectors
  ])
}

export function createGetBoundingClientRect(x = 0, y = 0) {
  return () => {
    const rect: DOMRect = {
      x, y,
      toJSON() {},
      left: x,
      right: x,
      top: y,
      bottom: y,
      width: 0,
      height: 0
    }
    return rect
  }
}

export const vRef: VirtualElement = {
  getBoundingClientRect: createGetBoundingClientRect()
}

export function useQuerySelector<T extends Element = HTMLElement>(
  selector: string
) {
  return useMemo(() => {
    if(!selector) return null
    return document.querySelector<T>(selector)
  }, [
    selector
  ])
}

/**
 * Retrieves DOM elements that might be added to the document
 * at a later point in time from when the function was called.
 * Will automatically clean up after itself when the element
 * was found
 *
 * @param selector A querySelector string
 * @param cb A function that receives the element
 * @param timeout A max duration for how long it should look for element
 *                (the interval is 50ms, which allows for 10 interation
 *                with the default timeout of 500ms)
 *
 * @returns Timeout handler so that the interval can be cleared
 *          outside this function
 */
export function querySelectorLazy<T extends Element = HTMLElement>(
  selector: string,
  cb: (el: T) => void,
  timeout?: number
): Timeout

export function querySelectorLazy<T extends Element = HTMLElement>(
  selector: string[],
  cb: (el: T[]) => void,
  timeout?: number
): Timeout

export function querySelectorLazy<T extends Element = HTMLElement>(
  _selector: string | string[],
  cb: (el: T | T[]) => void,
  timeout = 500
) {
  const interval = 50
  const selector = Array.isArray(_selector)
    ? [ ..._selector ]
    : _selector

  let elapsed = 0

  const t = setInterval(() => {
    if(elapsed >= timeout) {
      clearInterval(t)
      return
    }

    if(Array.isArray(selector)) {
      const els: T[] = []

      let i = 0
      for(const sel of selector) {
        const el = document.querySelector<T>(sel)
        if(el) {
          els.push(el)
          delete selector[i]
        }
        i += 1
      }

      if(els.length === selector.length) {
        clearInterval(t)
        cb(els)
      }
    }
    else {
      const el = document.querySelector<T>(selector)
      if(el) {
        clearInterval(t)
        cb(el)
      }
    }

    elapsed += interval
  }, interval)

  return t
}

export function useQuerySelectorLazy<T extends Element = HTMLElement>(
  selector: string
) {
  const [ elem, setElem ] = useState<T>()

  useEffect(() => {
    if(!selector) return null
    const t = querySelectorLazy<T>(selector, setElem)
    return () => {
      clearInterval(t)
    }
  }, [
    selector
  ])

  return elem
}

type DragElementOptions = {
  target: HTMLElement,
  activator?: HTMLElement,
  isCenterByDefault?: boolean
}

/**
 * @todo switch to transform over left/top for performance?
 */
export function dragElement(options: DragElementOptions) {
  const {
    isCenterByDefault = true,
    activator,
    target
  } = options

  let p1 = 0
  let p2 = 0
  let p3 = 0
  let p4 = 0

  const _activator = activator || target
  const boundaries = {
    left: 0,
    top: 0,
    right: window.innerWidth,
    bottom: window.innerHeight
  }

  function onResize() {
    boundaries.right = window.innerWidth
    boundaries.bottom = window.innerHeight
  }

  function startDrag(e: MouseEvent) {
    e.preventDefault()
    p3 = e.clientX
    p4 = e.clientY
    window.addEventListener("mousemove", onDrag)
    window.addEventListener("mouseup", stopDrag)
  }

  function stopDrag(e: MouseEvent) {
    e.preventDefault()
    window.removeEventListener("mousemove", onDrag)
    window.removeEventListener("mouseup", stopDrag)
  }

  let left = 0
  let top = 0
  let right = 0
  let bottom = 0

  function onDrag(e: MouseEvent) {
    e.preventDefault()
    p1 = p3 - e.clientX
    p2 = p4 - e.clientY
    p3 = e.clientX
    p4 = e.clientY

    left = target.offsetLeft - p1
    top = target.offsetTop - p2
    right = left + target.offsetWidth
    bottom = top + target.offsetHeight

    if(top >= boundaries.top && bottom <= boundaries.bottom) {
      target.style.top = `${top}px`
    }

    if(left >= boundaries.left && right <= boundaries.right) {
      target.style.left = `${left}px`
    }
  }

  // @note this was an attempt to automatically handle off-screen rendering
  // issues when contents of the target element changes in size. The problem
  // is that it's not always a wanted behaviour to center the target in all
  // cases where the element changes size, such as Dialog isFullscreen / isDraggable
  // const obs = isCenterByDefault
  //   ? new ResizeObserver(centerTarget)
  //   : null

  function centerTarget() {
    top = (window.innerHeight / 2) - (target.offsetHeight / 2)
    left = (window.innerWidth / 2) - (target.offsetWidth / 2)
    target.style.top = `${top}px`
    target.style.left = `${left}px`
  }

  return {
    activate() {
      window.addEventListener("resize", onResize)
      window.addEventListener("mouseleave", stopDrag)
      _activator.addEventListener("mousedown", startDrag)
      if(isCenterByDefault) {
        // obs.observe(target)
        centerTarget()
      }
    },
    deactivate() {
      window.removeEventListener("resize", onResize)
      window.removeEventListener("mousemove", onDrag)
      window.removeEventListener("mouseup", stopDrag)
      window.removeEventListener("mouseleave", stopDrag)
      _activator.removeEventListener("mousedown", startDrag)
      // if(isCenterByDefault) obs.unobserve(target)
    }
  }
}

export function useDragElement(
  target: HTMLElement,
  activator?: HTMLElement
) {
  useEffect(() => {
    if(!target) return
    const drag = dragElement({ target, activator })
    drag.activate()
    return () => {
      drag.deactivate()
    }
  }, [
    target,
    activator
  ])
}

export function copyHtmlTableToPlainText(table: HTMLTableElement) {
  let text = ""

  for(let i = 0; i < table.rows.length; i++) {
    const row = table.rows[i]
    for(let j = 0; j < row.cells.length; j++) {
      const cell = row.cells[j]
      text += cell.textContent.trim() + "\t"
    }
    text += "\n"
  }

  try {
    navigator.clipboard.writeText(text)
  } catch(error) {
    logError(error)
  }
}

import { useMutation, type MutationHookOptions } from "@apollo/client"
import mutation from "./currentUser.language.update.mutation.gql"

import type {
  CurrentUserLanguageUpdateMutation,
  CurrentUserLanguageUpdateMutationVariables
} from "./__types__/currentUser.language.update.mutation"

type Options = MutationHookOptions<
  CurrentUserLanguageUpdateMutation,
  CurrentUserLanguageUpdateMutationVariables
>

function useCurrentUserLanguageUpdateMutation(options?: Options) {
  return useMutation(mutation, options)
}

export default useCurrentUserLanguageUpdateMutation

import { useCallback, useState, type ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import Slider from "@/v2-ui/slider"
import Button from "@/v2-ui/button/Button"
import type { ThemeConfig } from "@/v2-ui/theme/theme.types"
import ThemeCustomizerFontSizePreview
  from "@/v2-console-ui/themeCustomizer/ThemeCustomizerFontSizePreview"

type ThemeCustomizerFontSizePickerProps = ComponentProps<"div"> & {
  themeConfig: ThemeConfig,
  setThemeConfig: (themeConfig: ThemeConfig) => void
}
function ThemeCustomizerFontSizePicker(props: ThemeCustomizerFontSizePickerProps) {
  const {
    themeConfig,
    setThemeConfig,
    className,
    ...divProps
  } = props

  const [ themePreviewConfig, setThemePreviewConfig ] = useState<ThemeConfig>(themeConfig)

  const submitPreview = useCallback(() => {
    setThemeConfig(themePreviewConfig)
  }, [ setThemeConfig, themePreviewConfig ])

  return (
    <div className="space-y-2">

      <div
        className={cn("flex justify-center items-center gap-2.5", className)}
        {...divProps}
      >
        <div className="text-muted-foreground text-sm">A</div>
        <Slider
          defaultValue={[ themeConfig.fontSize ]}
          min={13}
          max={19}
          step={1}
          onValueCommit={(v: number[]) => {
            setThemePreviewConfig({
              ...themeConfig,
              fontSize: v.at(0)
            })

          }}
        />
        <div className="text-xl font-bold">A</div>
      </div>

      <div className="px-2 space-y-2">
        <ThemeCustomizerFontSizePreview
          fontSize={themePreviewConfig.fontSize}
        />
        <Button
          className="w-full"
          onClick={submitPreview}
          disabled={themeConfig.fontSize === themePreviewConfig.fontSize}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

export default ThemeCustomizerFontSizePicker

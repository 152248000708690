import cn from "@/v2-ui/utils/utils.cn"
import Frame, { type FrameBackdropProps } from "@/v2-ui/frame"

export type DialogBackdropProps = FrameBackdropProps

function DialogBackdrop(props: DialogBackdropProps) {
  const { className, ...frameBackdropProps } = props
  return (
    <Frame.Backdrop
      className={cn("dialog-backdrop", className)}
      {...frameBackdropProps}
    />
  )
}

export default DialogBackdrop

import { useMutation, type MutationHookOptions } from "@apollo/client"
import mutation from "./currentUser.profile.mutation.gql"
import type {
  CurrentUserProfileMutation,
  CurrentUserProfileMutationVariables
} from "./__types__/currentUser.profile.mutation"

type Options = MutationHookOptions<
  CurrentUserProfileMutation,
  CurrentUserProfileMutationVariables
>

function useCurrentUserProfileMutation(options?: Options) {
  return useMutation(mutation, options)
}

export default useCurrentUserProfileMutation

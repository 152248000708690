import type { ComponentProps } from "react"
import { SidebarGroup as SidebarGroupBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarGroupProps = ComponentProps<typeof SidebarGroupBase>

function SidebarGroup(props: SidebarGroupProps) {
  const { className, ...sidebarGroupBaseProps } = props
  return (
    <SidebarGroupBase
      className={cn("ui-sidebar-group", className)}
      {...sidebarGroupBaseProps}
    />
  )
}

export default SidebarGroup

import type { ComponentProps } from "react"
import { Card as CardBase } from "@/v2-ui/card/base/CardBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CardProps = ComponentProps<typeof CardBase>

function Card(props: CardProps) {
  const { className, ...cardBaseProps } = props
  return (
    <CardBase className={cn("ui-card", className)}
      {...cardBaseProps}
    />
  )
}

export default Card

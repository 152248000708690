import type { ComponentProps } from "react"
import { ToastAction as ToastActionBase } from "@/v2-ui/toast/base/ToastBase"
import cn from "@/v2-ui/utils/utils.cn"

export type ToastActionProps = ComponentProps<typeof ToastActionBase>

function ToastAction(props: ToastActionProps) {
  const { className, ...toastActionBaseProps } = props
  return (
    <ToastActionBase
      className={cn("ui-toast-action", className)}
      {...toastActionBaseProps}
    />
  )
}

export default ToastAction

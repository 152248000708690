import cn from "@/v2-ui/utils/utils.cn"
import Command, { type CommandProps } from "@/v2-ui/command"
import Icon from "@/v2-ui/icon"
import ErrorMessage from "@/v2-ui/ErrorMessage"
import Skeleton from "@/v2-ui/skeleton"

export type CommandSimpleItemProps = {
  value: string | number
  label: string
  icon?: React.ReactNode
}

export type CommandSimpleProps = CommandProps & {
  items: CommandSimpleItemProps[]
  heading?: string,
  searchPlaceholder?: string
  emptyMessage?: string
  loading?: boolean
  onSelectChange?: (value: string | number) => void
  activeValue?: string | number,
  error?: Error,
}

function CommandSimple(props: CommandSimpleProps) {
  const {
    items,
    heading,
    searchPlaceholder = "Søk",
    emptyMessage,
    loading,
    onSelectChange,
    className,
    activeValue,
    error,
    ...commandProps
  } = props

  if(error) {
    return <ErrorMessage className="p-2" error={error} />
  }

  return (
    <Command
      className={className}
      {...commandProps}
    >
      <Command.Input
        className="h-9"
        disabled={loading || items?.length === 0}
        placeholder={searchPlaceholder}
      />
      <Command.List>
        <Command.Empty hidden={loading}>{emptyMessage}</Command.Empty>
        <Command.Group
          heading={!loading && items?.length === 0 ? "" : heading}
        >
          {
            loading ? (
              <div className="p-2 space-y-2">
                {Array.from({ length: items?.length || 3 }).map((_, index) => (
                  <Skeleton key={index} className="h-4 w-full" />
                ))}
              </div>
            )
              : (items.map((item) => (
                <Command.Item
                  key={item.value}
                  value={item.label?.toString()} // @note: this is what is used to search on in hte Command.inpu
                  onSelect={(currentLabel) => {
                    // @note: the onSelect returns the label (if not it is not searchable)
                    // And to safeguard that we have the correct reference, we loop trough the items instead of
                    // using item.value (which might have sideefects if the onSelect mutates the items array)
                    const currentValue = items.find((item) => item.label === currentLabel)?.value
                    if(activeValue === currentValue) return
                    onSelectChange?.(currentValue)
                  }}
                >
                  <div className="flex items-center">
                    {item.icon}
                    <span className={cn("ml-2", !item.icon && "ml-0")}>
                      {item.label}
                    </span>
                  </div>
                  {
                    activeValue === item.value && (
                      <Icon
                        src="check"
                        size={12}
                        className="ml-auto"
                      />
                    )
                  }
                </Command.Item>
              ))
              )
          }
        </Command.Group>
      </Command.List>
    </Command>
  )
}

export default CommandSimple

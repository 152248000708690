import type { TypePolicies } from "@apollo/client"
import { InMemoryCache, defaultDataIdFromObject } from "@apollo/client"

const typePolicies: TypePolicies = {
  Query: {
    fields: {
    }
  },
  Mutation: {
    fields: {
    }
  }
}

type DataObj = {
  Id?: string,
  Code?: string,
  __typename: string
}

const dataIdFromObject = (obj: DataObj) => {
  const id = obj.Id || obj.Code
  return id
    ? `${obj.__typename}:${id}`
    : defaultDataIdFromObject(obj)
}

const cache = new InMemoryCache({
  typePolicies,
  dataIdFromObject
})

export default cache

import type { ClickEvent } from "@/v2-ui/types"
import useEventListener from "@/v2-ui/utils/utils.eventListener"
import Frame from "@/v2-ui/frame"
import type { FrameProps } from "@/v2-ui/frame"
import DialogBackdrop from "./DialogBackdrop"
import DialogContainer, { type DialogContainerProps }
  from "./DialogContainer"

function preventEventPropagation(e: ClickEvent<HTMLDivElement>) {
  e.stopPropagation()
  e.nativeEvent.stopImmediatePropagation()
}

export type DialogProps
  = Omit<FrameProps, "children"> & DialogContainerProps

/**
 * Dialog component
 *
 * @param props.isAlwaysRendered - Controls if DOM elements are added
 * @param props.isPortal true by default
 * @param props.isClosedOnEscKey true by default
 * @param props.isDraggable false by default. Has a few quirks:
 *                        - It cannot be used in combination with a backdrop
 *                          (which is enabled by default)
 *                        - It will not take effect unless a <Dialog.Header>
 *                          is added by the consumer
 *                        - If the contents inside the Dialog grows in size, it
 *                          is recommended to set a fixed height/width, which can
 *                          be passed as props. Because the anchor point is
 *                          top/left, the Dialog can might render off-screen when
 *                          the previous position + the grown size exceeds the
 *                          viewport. An attempt was made to alleviate this by
 *                          re-positioning the Dialog automatically, but that
 *                          poses issues with isFullscreen and isDraggable.
 * @param props.isClosedOnOutsideClick Closes the Dialog when clicked outside
 *                                     of the container. false by default
 * @param props.isAnchored Puts the Dialog into a fixed-height mode. The content
 *                         should has it's own scroll container. false by default.
 *                         Does not make sense to use in combination with hasbackdrop
 * @param props.hasBackdrop This puts the dialog inside of a full height/width
 *                          wrapper element, in order to control it's positioning.
 *                          The background is also blurred, to put focus on the
 *                          dialog itself. true by default.
 */
function Dialog(props: DialogProps) {
  const {
    key,
    isShown,
    onShow,
    onHide,
    isFullscreen,
    onFullscreen,
    isAlwaysRendered,
    isClosedOnOutsideClick = false,
    isClosedOnEscKey = true,
    isAnchored = false,
    isDraggable = false,
    hasBackdrop = true,
    isPortal = true,
    portalElement,
    ...frameContainerProps
  } = props

  useEventListener<KeyboardEvent>("keydown", (e) => {
    if(!onHide || !isClosedOnEscKey) return
    if(e.key === "Escape") onHide()
  })

  let content = (
    <DialogContainer
      hasBackdrop={hasBackdrop}
      isAnchored={isAnchored}
      isDraggable={isDraggable}
      onClick={preventEventPropagation}
      onMouseUp={preventEventPropagation}
      {...frameContainerProps}
    />
  )

  if(hasBackdrop) {
    content = (
      <DialogBackdrop
        onClick={preventEventPropagation}
        onMouseUp={preventEventPropagation}
        onWheel={preventEventPropagation}
      >
        {content}
      </DialogBackdrop>
    )
  }

  return (
    <Frame
      key={key}
      isShown={isShown}
      onShow={onShow}
      onHide={onHide}
      isClosedOnOutsideClick={isClosedOnOutsideClick}
      isClosedOnEscKey={isClosedOnEscKey}
      isFullscreen={isFullscreen}
      onFullscreen={onFullscreen}
      isPortal={isPortal}
      isAlwaysRendered={isAlwaysRendered}
      portalElement={portalElement}
    >
      {content}
    </Frame>
  )
}

export default Dialog

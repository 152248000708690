import { useCallback, useEffect, useState } from "react"
import { useApolloClient } from "@apollo/client"
import type { CurrentUserFragment }
  from "@/v2-console-shared/currentUser/__types__/currentUser.fragment"
  import { useToast } from "@/v2-ui/toast/base/hooks/use-toast"
import { useAppContext } from "@/v2-console/app/context/AppContextProvider"
import { TENANT_SWITCH_EVENT }
  from "@/v2-console/currentUser/currentUser.utils"
import useCurrentUserTenantMutation
  from "@/v2-console/currentUser/tenant/currentUser.tenant.mutation"
import { trackCurrentUserMenuActiveTenantChanged }
  from "@/v2-console/currentUser/currentUser.tracking"

export function useTenants() {
  const { currentUser } = useAppContext()
  const [ userTenants, setUserTenants ] = useState<CurrentUserFragment["UserTenants"]>([])
  const [ activeTenant, setActiveTenant ] = useState<CurrentUserFragment["UserTenants"][0]>(null)

  useEffect(() => {
    if(!currentUser) return
    const uts = currentUser.UserTenants
    if(!uts || uts.length === 0) return

    const id = currentUser.ActiveTenantId
    const rest = []
    let active = uts[0]

    for(const ut of uts) {
      if(ut.TenantId === id) active = ut
      rest.push(ut)
    }

    if(rest.length > 0) {
      setUserTenants(rest)
    }

    if(active) {
      setActiveTenant(active)
    }
  }, [
    currentUser
  ])

  return {
    userTenants,
    activeTenant
  }
}

export function useTenantChange() {
  const { events } = useAppContext()
  const client = useApolloClient()
  const { toast } = useToast()

  const [ mutate, { error, loading }] = useCurrentUserTenantMutation()

  const onTenantChange = useCallback((
    newTenant: CurrentUserFragment["UserTenants"][0]
  ) => {
    mutate({
      variables: {
        input: {
          tenantId: newTenant.TenantId
        }
      },
      onCompleted() {
        events.dispatchEvent(new Event(TENANT_SWITCH_EVENT))
        // Ensure all components are closed during the event above
        // because apollo will automatically refetch all cached queries
        setTimeout(() => {
          client.resetStore()
        }, 50)
        trackCurrentUserMenuActiveTenantChanged()
        toast({
          title: "Suksess",
          description: "Medlemskap har endret seg."
        })
      },
      onError() {
        toast({
          variant: "destructive",
          title: "Kunne ikke bytte medlemskap",
          description: (
            <>
                Mottok følgende feilmelding: <strong>{error?.message ?? "Something went wrong!" } </strong>
            </>
          )
        })
      }
    })
  }, [
    mutate,
    events,
    client,
    toast,
    error
  ])

  return {
    onTenantChange,
    tenantChangeError: error,
    tenantChangeIsLoading: loading
  }
}

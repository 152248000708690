import type { ComponentProps } from "react"
import { SheetPortal, SheetContent as SheetContentBase }
  from "@/v2-ui/sheet/base/SheetBase"
import { useQuerySelectorLazy }
  from "@/v2-ui/utils/utils.dom"
import cn from "@/v2-ui/utils/utils.cn"

const DEAFAULT_PORTAL_CONTAINER = "#mount"

export type SheetContentProps = ComponentProps<typeof SheetContentBase> & {
  portalContainer?: string
}

function SheetContent(props: SheetContentProps) {
  const {
    portalContainer = DEAFAULT_PORTAL_CONTAINER,
    className,
    ...sheetContentBaseProps
  } = props

  const portalElem = useQuerySelectorLazy(portalContainer)

  return (
    <SheetPortal
      container={portalElem}
    >
      <SheetContentBase
        className={cn("ui-sheet-content", className)}
        {...sheetContentBaseProps}
      />
    </SheetPortal>
  )
}

export default SheetContent

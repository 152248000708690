import type { ComponentProps } from "react"
import { SidebarMenuSub as SidebarMenuSubBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarMenuSubProps = ComponentProps<typeof SidebarMenuSubBase>

function SidebarMenuSub(props: SidebarMenuSubProps) {
  const { className, ...sidebarMenuSubBaseProps } = props
  return (
    <SidebarMenuSubBase
      className={cn("ui-sidebar-menu-sub", className)}
      {...sidebarMenuSubBaseProps}
    />
  )
}

export default SidebarMenuSub

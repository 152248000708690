import type { ComponentProps } from "react"
import { CommandGroup as CommandGroupBase } from "@/v2-ui/command/base/CommandBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CommandGroupProps = ComponentProps<typeof CommandGroupBase>

function CommandGroup(props: CommandGroupProps) {
  const { className, ...commandGroupBaseProps } = props
  return (
    <CommandGroupBase
      className={cn("ui-command-group", className)}
      {...commandGroupBaseProps}
    />
  )
}

export default CommandGroup

import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import "@/v2-console/auth/auth.instance"
import "@/v2-console/sentry"
import "@/v2-console-shared/analytics"
import "@/v2-ui/css/index.css"
import App from "@/v2-console/app/App"
import "@/v2-ui/css/utilities.css"

const el = document.querySelector<HTMLDivElement>("#root")
if(el) {
  const root = createRoot(el)
  root.render(<StrictMode><App /></StrictMode>)
}

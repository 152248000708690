import type { ComponentProps } from "react"
import { DropdownMenuRadioItem as DropdownMenuBaseRadioItemBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"
import cn from "@/v2-ui/utils/utils.cn"

export type DropdownMenuRadioItemProps = ComponentProps<typeof DropdownMenuBaseRadioItemBase>

function DropdownMenuRadioItem(props: DropdownMenuRadioItemProps) {
  const { className, ...dropdownMenuBaseRadioItemBaseProps } = props

  return (
    <DropdownMenuBaseRadioItemBase
      className={cn("ui-dropdown-menu-radio-item", className)}
      {...dropdownMenuBaseRadioItemBaseProps}
    />
  )
}

export default DropdownMenuRadioItem

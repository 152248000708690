import Sidebar from "@/v2-ui/sidebar"

export type SidebarGroupProps = {
  label?: string,
  className?: string,
  children: React.ReactNode
}

function SidebarGroup(props: SidebarGroupProps) {
  const { label, className, children } = props

  return (
    <Sidebar.Group className={className}>
      {label
        ? <Sidebar.Group.Label>{label}</Sidebar.Group.Label>
        : null
      }
      <Sidebar.Menu>
        <Sidebar.Group.Content>
          {children}
        </Sidebar.Group.Content>
      </Sidebar.Menu>
    </Sidebar.Group>
  )
}

export default SidebarGroup

import { useState, forwardRef, useEffect, type ComponentProps } from "react"
import type { VariantProps } from "class-variance-authority"
import { cva } from "class-variance-authority"
import { PopoverContent as PopoverContentBase, PopoverPortal }
  from "@/v2-ui/popover/base/PopoverBase"
import { useIsMobile } from "@/v2-ui/utils/utils.mobile"
import { useQuerySelectorLazy } from "@/v2-ui/utils/utils.dom"
import cn from "@/v2-ui/utils/utils.cn"

const popoverContentVariations = cva("ui-popover-content", {
  variants: {
    size: {
      default: "size-default size-default--spacing",
      lg: "size-lg size-lg--spacing",
      md: "size-md size-md--spacing"
    }
  },
  defaultVariants: {
    size: "default"
  }
})

const DEFAULT_COLLISION_PADDING = 6
const DEAFAULT_PORTAL_CONTAINER = "#mount"

export type PopoverContentProps = Omit<ComponentProps<typeof PopoverContentBase>, "collisionBoundary">
  & VariantProps<typeof popoverContentVariations> & {
    portalContainer?: string
    collisionBoundary?: Element | string
  }

function PopoverContent(
  props: PopoverContentProps,
  ref
) {
  const {
    size,
    className,
    portalContainer = DEAFAULT_PORTAL_CONTAINER,
    collisionBoundary,
    collisionPadding = DEFAULT_COLLISION_PADDING,
    ...popoverContentCoreProps
  } = props

  const portalElem = useQuerySelectorLazy(portalContainer)

  const [ collisionElem, setCollisionElem ] = useState<Element>()

  useEffect(() => {
    if(!collisionBoundary || collisionElem) return
    if(collisionBoundary && typeof collisionBoundary !== "string") {
      setCollisionElem(collisionBoundary)
      return
    }

    let el: Element

    if(typeof collisionBoundary === "string") {
      el = document.querySelector(collisionBoundary)
    }

    if(el) setCollisionElem(el)
  }, [ collisionBoundary, collisionElem ])

  const isMobile = useIsMobile()

  return (
    <PopoverPortal
      container={portalElem}
    >
      <PopoverContentBase
        collisionBoundary={collisionElem}
        collisionPadding={isMobile ? 0 : collisionPadding}
        className={cn(popoverContentVariations({
          size, className
        }),
          { "!w-screen": isMobile }
        )}
        ref={ref}
        {...popoverContentCoreProps}
      />
    </PopoverPortal>
  )
}

export default forwardRef(PopoverContent)

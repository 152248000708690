import deepmerge from "deepmerge"
import { captureException, setUser } from "@sentry/react"
import {
  PublicClientApplication,
  LogLevel,
  EventType,
  InteractionRequiredAuthError
} from "@azure/msal-browser"
import type {
  ILoggerCallback,
  RedirectRequest,
  EndSessionRequest,
  EventMessage,
  AuthenticationResult
} from "@azure/msal-browser"
import { logError, logWarning } from "@/v2-ui/utils/utils.log"
import { ONE_HOUR_IN_MS } from "@/v2-console/constants"
import history from "@/v2-console/router/router.history"
import config from "@/v2-console-shared/config"
import { trackAuthLogin, trackAuthLogout }
  from "@/v2-console/auth/auth.tracking"

const {
  azAuthLoginDomain,
  azAuthTenantId,
  azAuthClientId,
  azAuthLoginPolicyName,
  azAuthRegisterPolicyName,
  azAuthRedirectUrl,
  azAuthPostLogoutRedirectUri,
  isProd,
  isDev,
  authUrl
} = config

const loggerCallback: ILoggerCallback = (level, msg, _hasPii) => {
  // if(hasPii) return
  // if(level === LogLevel.Verbose) {
  //   console.debug(msg)
  // }
  // if(level === LogLevel.Info) {
  //   console.info(msg)
  // }
  if(level === LogLevel.Warning) {
    logWarning(msg)
  }
  if(level === LogLevel.Error) {
    logError(msg)
  }
}

export const instance = new PublicClientApplication({
  auth: {
    clientId: azAuthClientId,
    knownAuthorities: [ azAuthLoginDomain ],
    redirectUri: azAuthRedirectUrl,
    postLogoutRedirectUri: azAuthPostLogoutRedirectUri
  },
  cache: {
    cacheLocation: isDev
      ? "sessionStorage"
      : "localStorage"
  },
  system: {
    loggerOptions: {
      loggerCallback
    }
  }
})

export let idToken: string = null

const accounts = instance.getAllAccounts()
if(accounts.length > 0) {
  instance.setActiveAccount(accounts[0])
}

instance.addEventCallback((e: EventMessage) => {
  if(e.eventType === EventType.LOGIN_SUCCESS
    || e.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
  ) {
    const d = e.payload as AuthenticationResult
    idToken = d.idToken
    instance.setActiveAccount(d.account)
  }

  if(e.eventType === EventType.LOGIN_SUCCESS) {
    trackAuthLogin()
  }

  if(e.eventType === EventType.LOGOUT_START) {
    trackAuthLogout()
    instance.setActiveAccount(null)
    idToken = null
    setUser(null)
  }

  if(e.error) {
    onAuthError(e)
  }
})

function onAuthErrorInteractionRequired(_e: EventMessage) {
  instance.acquireTokenRedirect({
    ...loginRequest,
    account: instance.getActiveAccount()
  })
}

function onAuthError(e: EventMessage) {
  if(e.error instanceof InteractionRequiredAuthError) {
    onAuthErrorInteractionRequired(e)
    return
  }

  if(!isProd) {
    logError(e.error)
    return
  }

  captureException(e.error, {
    contexts: {
      "Auth": {
        eventType: e.eventType,
        interactionType: e.interactionType,
        payload: e.payload,
        timestamp: e.timestamp
      }
    }
  })
}

const DEFAULT_REQUEST = {
  scopes: [
    "openid",
    "offline_access",
    "email",
    "profile",
    azAuthClientId
  ],
  extraQueryParameters: {
    ui_locales: "nb"
  }
}

function getAuthority(policy: string) {
  return `https://${azAuthLoginDomain}/${azAuthTenantId}/${policy}`
}

function getAccountUserName() {
  const account = instance.getActiveAccount()
  return account?.username
}

const loginRequest: RedirectRequest = {
  ...DEFAULT_REQUEST,
  authority: getAuthority(azAuthLoginPolicyName)
}

const registerRequest: RedirectRequest = {
  ...DEFAULT_REQUEST,
  authority: getAuthority(azAuthRegisterPolicyName)
}

const logoutRequest: EndSessionRequest = {
  ...DEFAULT_REQUEST,
  authority: getAuthority(azAuthLoginPolicyName)
}

export function register(request: Partial<RedirectRequest> = {}) {
  return instance.loginRedirect(deepmerge(registerRequest, request))
}

export function login(request: Partial<RedirectRequest> = {}) {
  return instance.loginRedirect(deepmerge({
    prompt: "login",
    loginHint: getAccountUserName(),
    ...loginRequest
  }, request))
}

export function logout() {
  instance.logoutRedirect({
    logoutHint: getAccountUserName(),
    idTokenHint: idToken,
    ...logoutRequest
  })
}

export function acquireTokenSilent() {
  return instance.acquireTokenSilent({
    redirectUri: "/static/blank.html",
    account: instance.getActiveAccount(),
    ...loginRequest
  })
}

const t = setInterval(async () => {
  try {
    await acquireTokenSilent()
  } catch(error) {
    logError(error)
    clearInterval(t)
  }
}, ONE_HOUR_IN_MS)

export async function checkTokenIsInSyncWithApi() {
  const browserLanguageCode = navigator.language
  const authUrlWithBrowserLanguageCode = authUrl + `?browserLanguageCode=${browserLanguageCode}`
  const res = await fetch(authUrlWithBrowserLanguageCode, {
    headers: {
      authorization: `Bearer ${idToken}`
    }
  })

  if(res.status !== 204) {
    let msg = res.statusText

    if(res.status === 401) {
      try {
        const r = await res.json()
        msg = r.error
      } catch(error) {
        logError(error)
      }
    }

    throw new Error(msg || "Kommunikasjon med server feilet")
  }

  return true
}

export async function acquireAndCheckTokenOrRedirectToLogin() {
  try {
    await acquireTokenSilent()
    await checkTokenIsInSyncWithApi()
  } catch(error) {
    if(error instanceof InteractionRequiredAuthError) {
      return
    }

    logError(error)

    if(window.location.pathname === "/login") return

    let url = "/login"
    if(window.location.pathname) {
      url = `${url}?r=${window.location.pathname}`
    }
    history.push(url, { error: error })
  }
}

export async function onTokenExpired() {
  // @note: this will not work until the @ui/Notification component is imported into v2-console. Because notificatoin adds the addNotification to the window object
  // const clearWarning = window.addNotification({
  //   intent: "warning",
  //   timeout: false,
  //   iconSrc: "exclamation",
  //   loading: true,
  //   title: "Utløpt økt",
  //   content: "Vennligst vent mens vi autentiserer på nytt"
  // })
  await acquireAndCheckTokenOrRedirectToLogin()
  console.warn("Utløpt økt. Vennligst vent mens vi autentiserer på nytt...")
  // clearWarning()
}

import { forwardRef, type ComponentProps } from "react"
import { SidebarMenuAction as SidebarMenuActionBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarMenuActionProps = ComponentProps<typeof SidebarMenuActionBase>

function SidebarMenuAction(props: SidebarMenuActionProps, ref) {
  const { showOnHover, className, ...sidebarMenuActionBaseProps } = props
  return (
    <SidebarMenuActionBase
      className={cn("ui-sidebar-menu-action", className, {
        "show-on-hover": showOnHover
      })}
      ref={ref}
      {...sidebarMenuActionBaseProps}
    />
  )
}

export default forwardRef(SidebarMenuAction)

import type { ComponentProps } from "react"
import { AvatarFallback as AvatarFallbackBase } from "@/v2-ui/avatar/base/AvatarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type AvatarFallbackProps = ComponentProps<typeof AvatarFallbackBase>

function AvatarFallback(props: AvatarFallbackProps) {
  const { className, ...avatarFallbackBaseProps } = props
  return (
    <AvatarFallbackBase
      className={cn("ui-avatar-fallback", className)}
      {...avatarFallbackBaseProps}
    />
  )
}

export default AvatarFallback

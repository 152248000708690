import type { ComponentProps } from "react"
import { DropdownMenu as DropdownMenuBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"

export type DropdownMenuProps = ComponentProps<typeof DropdownMenuBase>

function DropdownMenu(props: DropdownMenuProps) {
  return <DropdownMenuBase {...props} />
}

export default DropdownMenu

import type { ComponentProps } from "react"
import { Tooltip as TooltipBase } from "@/v2-ui/tooltip/base/TooltipBase"

export type TooltipProps = ComponentProps<typeof TooltipBase>

function Tooltip(props: TooltipProps) {
  return <TooltipBase {...props} />
}

export default Tooltip

import type { ComponentProps } from "react"
import { FormDescription as FormDescriptionBase } from "@/v2-ui/form/base/FormBase"
import cn from "@/v2-ui/utils/utils.cn"

export type FormDescriptionProps = ComponentProps<typeof FormDescriptionBase>

function FormDescription(props: FormDescriptionProps) {
  const { className, ...formBaseProps } = props

  return (
    <FormDescriptionBase
      className={cn("ui-form-description", className)}
      {...formBaseProps}
    />
  )
}

export default FormDescription

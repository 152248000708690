import "./icon.custom.compass.css"
import type { ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"

export type IconCustomCompassProps = ComponentProps<"svg">
function IconCustomCompass(props: IconCustomCompassProps) {
  const {
    className,
    viewBox = "0 0 20 24",
    fill = "none",
    ...svgProps
  } = props

  return (
    <svg
      className={cn("compass", className)}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <mask
        id="path-1-inside-1_256_1395"
        fill="white"
      >
        <path
          d="M3.45925 7.45931C3.16636 7.16642 2.68908 7.16469 2.41896 7.4787C1.2981 8.78167 0.5291 10.3552 0.192147 12.0491C-0.193704 13.9889 0.00432847 15.9996 0.761202 17.8269C1.51808 19.6541 2.7998 21.2159 4.44428 22.3147C6.08877 23.4135 8.02216 24 9.99997 24C11.9778 24 13.9112 23.4135 15.5557 22.3147C17.2001 21.2159 18.4819 19.6541 19.2387 17.8269C19.9956 15.9996 20.1936 13.9889 19.8078 12.0491C19.4708 10.3552 18.7018 8.78167 17.581 7.4787C17.3109 7.16469 16.8336 7.16642 16.5407 7.45931C16.2478 7.7522 16.2507 8.22512 16.5166 8.54268C17.4299 9.63318 18.0576 10.9389 18.3366 12.3418C18.6646 13.9906 18.4963 15.6997 17.8529 17.2528C17.2096 18.806 16.1201 20.1335 14.7223 21.0675C13.3245 22.0015 11.6811 22.5 9.99997 22.5C8.31883 22.5 6.67545 22.0015 5.27764 21.0675C3.87982 20.1335 2.79036 18.806 2.14702 17.2528C1.50367 15.6997 1.33535 13.9906 1.66332 12.3418C1.94237 10.9389 2.57005 9.63318 3.4833 8.54268C3.74925 8.22511 3.75215 7.7522 3.45925 7.45931Z"
        />
      </mask>
      <path
        className="compass__svg__eclipse"
        d="M3.45925 7.45931C3.16636 7.16642 2.68908 7.16469 2.41896 7.4787C1.2981 8.78167 0.5291 10.3552 0.192147 12.0491C-0.193704 13.9889 0.00432847 15.9996 0.761202 17.8269C1.51808 19.6541 2.7998 21.2159 4.44428 22.3147C6.08877 23.4135 8.02216 24 9.99997 24C11.9778 24 13.9112 23.4135 15.5557 22.3147C17.2001 21.2159 18.4819 19.6541 19.2387 17.8269C19.9956 15.9996 20.1936 13.9889 19.8078 12.0491C19.4708 10.3552 18.7018 8.78167 17.581 7.4787C17.3109 7.16469 16.8336 7.16642 16.5407 7.45931C16.2478 7.7522 16.2507 8.22512 16.5166 8.54268C17.4299 9.63318 18.0576 10.9389 18.3366 12.3418C18.6646 13.9906 18.4963 15.6997 17.8529 17.2528C17.2096 18.806 16.1201 20.1335 14.7223 21.0675C13.3245 22.0015 11.6811 22.5 9.99997 22.5C8.31883 22.5 6.67545 22.0015 5.27764 21.0675C3.87982 20.1335 2.79036 18.806 2.14702 17.2528C1.50367 15.6997 1.33535 13.9906 1.66332 12.3418C1.94237 10.9389 2.57005 9.63318 3.4833 8.54268C3.74925 8.22511 3.75215 7.7522 3.45925 7.45931Z"
        mask="url(#path-1-inside-1_256_1395)"
      />
      <path
        className="compass__svg__north-arrow"
        d="M9.60745 9.02684L7.62056 14L9.16129 14L10.8388 14H12.3796L10.3927 9.02684C10.2529 8.67288 9.7532 8.67288 9.6134 9.02684L9.60745 9.02684Z"
      />
      <path
        className="compass__svg__south-arrow"
        d="M10.8388 13.9998H9.16121H7.62048L9.60737 18.973C9.74717 19.3269 10.2469 19.3269 10.3867 18.973L12.3736 13.9998H10.8328H10.8388Z"
      />
      <path
        className="compass__svg__north-text"
        d="M12.5037 0.999939V0.899939H12.4037H11.3497H11.2497V0.999939V4.7412L8.62694 0.943116L8.59712 0.899939H8.54465H7.59631H7.49631V0.999939V6.84994V6.94994H7.59631H8.65605H8.75605V6.84994V3.11534L11.3788 6.90683L11.4087 6.94994H11.4611H12.4037H12.5037V6.84994V0.999939Z"
      />
    </svg>
  )
}

export default IconCustomCompass

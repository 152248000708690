import type { SubmitHandler, UseFormReturn } from "react-hook-form"
import type { CurrentUserProfileUpdateInput }
  from "@/v2-common/__types__/schema.types"
import Input from "@/v2-ui/input"
import Skeleton from "@/v2-ui/skeleton"
import Card from "@/v2-ui/card"
import Button from "@/v2-ui/button"
import Separator from "@/v2-ui/separator"
import Form from "@/v2-ui/form"
import FormField from "@/v2-ui/form/FormField"
import FormItem from "@/v2-ui/form/FormItem"
import FormLabel from "@/v2-ui/form/FormLabel"
import FormControl from "@/v2-ui/form/FormControl"
import FormMessage from "@/v2-ui/form/FormMessage"
import Popover from "@/v2-ui/popover"
import Header from "@/v2-layout-ui/header"
import { CloseButton } from "@/v2-layout-ui/buttons"

type FormProps = CurrentUserProfileUpdateInput

export type CurrentUserProfileFormProps = {
  form: UseFormReturn<FormProps>
  onSubmit: SubmitHandler<FormProps>
  userIsLoading?: boolean
  onCancel?: () => void,
  loading?: boolean
}

const CurrentUserProfileForm = (props: CurrentUserProfileFormProps) => {
  const { form, onSubmit, userIsLoading, onCancel, loading } = props

  const formControls = [
    {
      name: "firstName",
      label: "Fornavn (*)",
      placeholder: "Skriv inn ditt fornavn.."
    },
    {
      name: "lastName",
      label: "Etternavn (*)",
      placeholder: "Skriv inn ditt etternavn.."
    },
    {
      name: "email",
      label: "E-post (*)",
      placeholder: "Skriv inn din e-post.."
    }
  ]

  return (
    <Card className="border-none shadow-none">
      <Header className="px-2 py-3"
        title="Profile"
        description="Edit your profile"
        renderCloseButton={(
          <Popover.Close
            className="ml-auto"
            asChild
          >
            <CloseButton />
          </Popover.Close>
        )}
      />
      <Separator />
      <Card.Content className="px-2">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            {formControls.map((control) => (
              <FormField
                key={control.name}
                control={form.control}
                name={control.name}
                render={({ field }) => (
                  <FormItem className="pt-2">
                    <FormLabel>{control?.label}</FormLabel>
                    <FormControl>
                      {userIsLoading ? (
                        <Skeleton className="h-9 w-full" />
                      ) : (
                        <Input
                          placeholder={control?.placeholder}
                          {...field}
                          className="current-user-profile__active-tenant-input "
                        />
                      )}
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}
          </form>
        </Form>
      </Card.Content>
      <Card.Footer className="flex justify-between w-full px-2 py-2">
        <Button
          size="sm"
          variant="outline"
          onClick={onCancel}
          disabled={loading || userIsLoading}
        >
          Avbryt
        </Button>
        <Button
          size="sm"
          variant="default"
          type="submit"
          isLoading={loading}
          disabled={loading || userIsLoading}
          onClick={form.handleSubmit(onSubmit)}
        >
          Lagre
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default CurrentUserProfileForm

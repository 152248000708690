import Sidebar, { type SidebarItemProps }
  from "@/v2-layout-ui/sidebar"
import LanguageSelector
  from "@/v2-console-ui/languageSelector/LanguageSelector"
import { useCurrentUserLanguage }
  from "@/v2-console/currentUser/language/currentUser.language.utils"

export type AppSidebarItemLanguageProps = Partial<SidebarItemProps>

function AppSidebarItemLanguage(props: AppSidebarItemLanguageProps) {
  const { onClick, ...sidebarItemProps } = props

  const {
    activeLanguage,
    loading,
    error,
    languages,
    onLanguageChange
  } = useCurrentUserLanguage()

  return (
    <Sidebar.Item
      onClick={onClick}
      label={activeLanguage?.NativeName}
      iconSrc="language"
      isLoading={loading}
      tooltip="Velg språk"
      contentSize="md"
      popoverContent={
        () => (
          <LanguageSelector
            activeLanguage={activeLanguage}
            languages={languages}
            onLanguageChange={onLanguageChange}
            loading={loading}
            error={error}
          />
        )
      }
      {...sidebarItemProps}
    />
  )
}

export default AppSidebarItemLanguage

import cn from "@/v2-ui/utils/utils.cn"
import { TooltipButton, type TooltipButtonProps } from "@/v2-ui/button"
import { useFrameContext } from "@/v2-ui/frame/frame.context"

export type DialogCloseButtonProps = TooltipButtonProps

function DialogCloseButton(props: DialogCloseButtonProps) {
  const { className, onClick, ...tooltipButtonProps } = props
  const { onHide } = useFrameContext()
  return (
    <TooltipButton
      tooltipTriggerClassName={cn("dialog-close-button", className)}
      variant="ghost"
      size="fit"
      iconSrc="cross"
      content="Lukk (esc)"
      onClick={(e) => {
        if(onClick) onClick(e)
        onHide()
      }}
      {...tooltipButtonProps}
    />
  )
}

export default DialogCloseButton

import "./form.css"
import Form from "./Form"
import FormField from "./FormField"
import FormItem from "./FormItem"
import FormLabel from "./FormLabel"
import FormControl from "./FormControl"
import FormDescription from "./FormDescription"
import FormMessage from "./FormMessage"

export { useFormField } from "./base/FormBase"
export type { FormProps } from "./Form"
export type { FormFieldProps } from "./FormField"
export type { FormItemProps } from "./FormItem"
export type { FormLabelProps } from "./FormLabel"
export type { FormControlProps } from "./FormControl"
export type { FormDescriptionProps } from "./FormDescription"
export type { FormMessageProps } from "./FormMessage"
export default Object.assign(Form, {
  Field: FormField,
  Item: FormItem,
  Label: FormLabel,
  Control: FormControl,
  Description: FormDescription,
  Message: FormMessage
})

import "./dialog.css"

import Dialog from "./Dialog"
import DialogHeader from "./DialogHeader"
import DialogHeaderActions from "./DialogHeaderActions"
import DialogBody from "./DialogBody"
import DialogFooter from "./DialogFooter"
import DialogBackdrop from "./DialogBackdrop"
import DialogCloseButton from "./DialogCloseButton"
import DialogFullscreenButton from "./DialogFullscreenButton"

export type { DialogProps } from "./Dialog"
export type { DialogHeaderProps } from "./DialogHeader"
export type { DialogHeaderActionsProps } from "./DialogHeaderActions"
export type { DialogBodyProps } from "./DialogBody"
export type { DialogFooterProps } from "./DialogFooter"
export type { DialogBackdropProps } from "./DialogBackdrop"
export type { DialogCloseButtonProps } from "./DialogCloseButton"
export type { DialogFullscreenButtonProps } from "./DialogFullscreenButton"

const components = {
  Header: Object.assign(DialogHeader, {
    Actions: DialogHeaderActions
  }),
  Body: DialogBody,
  Footer: DialogFooter,
  Backdrop: DialogBackdrop,
  CloseButton: DialogCloseButton,
  FullscreenButton: DialogFullscreenButton
}

export default Object.assign(Dialog, components)

import Button, { type ButtonProps } from "@/v2-ui/button"

type TextErrorNotificationProps = {
  error: Error,
  onRetry?: ButtonProps["onClick"],
}

function TextErrorNotification(
  props: TextErrorNotificationProps
) {
  const { error, onRetry } = props
  return (
    <div>
      Unfortunately, we could not fetch the specified language. You should try again or try logging in again. We apologize for this.

      <div className="mt-2">
        We received an error message: <strong>{error.message}</strong>
      </div>

        <Button
          size="sm"
          className="text-error__retry mt-3"
          variant="default"
          onClick={onRetry}
        >
          Try again..
        </Button>
    </div>
  )
}

export default TextErrorNotification

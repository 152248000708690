import type { ComponentProps } from "react"
import { DropdownMenuPortal as DropdownMenuBasePortalBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"

export type DropdownMenuPortalProps = ComponentProps<typeof DropdownMenuBasePortalBase>

function DropdownMenuPortal(props: DropdownMenuPortalProps) {
  return <DropdownMenuBasePortalBase {...props} />
}

export default DropdownMenuPortal

import type { ComponentProps } from "react"
import { SidebarMenuSubButton as SidebarMenuSubButtonBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarMenuSubButtonProps = ComponentProps<typeof SidebarMenuSubButtonBase>

function SidebarMenuSubButton(props: SidebarMenuSubButtonProps) {
  const { className, size, ...sidebarMenuSubButtonBaseProps } = props
  return (
    <SidebarMenuSubButtonBase
      className={cn("ui-sidebar-menu-sub-button", className, {
        "text-xs": size === "xs",
        "text-sm": size === "sm"
      })}
      data-size={size}
      {...sidebarMenuSubButtonBaseProps}
    />
  )
}

export default SidebarMenuSubButton

import type { ComponentProps } from "react"
import { DropdownMenuSubContent as DropdownMenuSubContentBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"
import cn from "@/v2-ui/utils/utils.cn"

export type DropdownMenuSubContentProps = ComponentProps<typeof DropdownMenuSubContentBase>

function DropdownMenuSubContent(props: DropdownMenuSubContentProps) {
  const { className, ...dropdownMenuSubContentBaseProps } = props
  return (
    <DropdownMenuSubContentBase
      className={cn("ui-dropdown-menu-sub-content", className)}
      {...dropdownMenuSubContentBaseProps}
    />
  )
}

export default DropdownMenuSubContent

import type { ComponentProps } from "react"
import type { SidebarTrigger as SidebarTriggerBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import SidebarBase from "@/v2-ui/sidebar"
import { useSidebarContext }
  from "@/v2-layout-ui/sidebar"

export type SidebarToggleProps = ComponentProps<typeof SidebarTriggerBase>

function SidebarToggle(props: SidebarToggleProps) {
  const {
    iconSrc = "sidebar",
    variant = "outline",
    size = "icon",
    onClick,
    ...sidebarTriggerProps
  } = props

  const { isMini, setIsMini } = useSidebarContext()
  return (
    <SidebarBase.Trigger
      iconSrc={iconSrc}
      variant={variant}
      size={size}
      onClick={(e) => {
        setIsMini(!isMini)
        onClick?.(e)
      }}
      {...sidebarTriggerProps}
    />
  )
}

export default SidebarToggle

import type { ComponentProps } from "react"
import { CommandEmpty as CommandEmptyBase } from "@/v2-ui/command/base/CommandBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CommandEmptyProps = ComponentProps<typeof CommandEmptyBase>

function CommandEmpty(props: CommandEmptyProps) {
  const { className, ...commandEmptyBaseProps } = props
  return (
    <CommandEmptyBase
      className={cn("ui-command-empty", className)}
      {...commandEmptyBaseProps}
    />
  )
}

export default CommandEmpty

import { useEffect } from "react"
import { z } from "zod"
import { useForm, type SubmitHandler } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import type { CurrentUserProfileUpdateInput } from "@/v2-common/__types__/schema.types"
import { useToast } from "@/v2-ui/toast/base/hooks/use-toast"
import { useAppContext } from "@/v2-console/app/context/AppContextProvider"
import { useAppContextQueryIsLoading }
  from "@/v2-console/app/context/app.context.query"
import { trackCurrentUserProfileUpdated } from "@/v2-console/currentUser/currentUser.tracking"
import useCurrentUserProfileMutation from
  "@/v2-console/currentUser/profile/currentUser.profile.mutation"
import type { CurrentUserProfileMutationVariables }
  from "@/v2-console/currentUser/profile/__types__/currentUser.profile.mutation"

const FormSchema = z.object({
    firstName: z.string()
          .min(1, { message: "Vennligst skriv inn ditt fornavn" })
          .max(64, { message: "Fornavn kan ikke være lengre enn 64 tegn" }),
    lastName: z.string()
          .min(1, { message: "Vennligst skriv inn ditt etternavn" })
          .max(64, { message: "Etternavn kan ikke være lengre enn 255 tegn" }),
    email: z.string()
          .min(1, { message: "Vennligst skriv inn din e-post" })
          .max(255, { message: "E-post kan ikke være lengre enn 255 tegn" })
          .email({ message: "Vennligst skriv inn en gyldig e-postadresse" })
  })

export type ProfileFormFields = Partial<CurrentUserProfileMutationVariables["input"]>

export const useCurrentUserProfile = () => {
  const { currentUser } = useAppContext()
  const userIsLoading = useAppContextQueryIsLoading()

  const { toast } = useToast()

  const [ submit, { loading, error }] = useCurrentUserProfileMutation()

  const onSubmit: SubmitHandler<CurrentUserProfileUpdateInput> = (values: CurrentUserProfileUpdateInput, e) => {
    e.preventDefault()
    e.stopPropagation()

    submit({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email
        }
      },
      update(cache, res) {
        const d = res.data.updateCurrentUserProfile
        cache.modify({
          id: `CurrentUser:${d.Id}`,
          fields: {
            FirstName() {
              return d.FirstName
            },
            LastName() {
              return d.LastName
            },
            Email() {
              return d.Email
            },
            TaskEmailNotification() {
              return d.TaskEmailNotification
            }
          }
        })
      },
      onCompleted() {
        trackCurrentUserProfileUpdated()
        toast({
          title: "Suksess",
          description: "Profilen din er oppdatert."
        })
      },
      onError() {
        toast({
          variant: "destructive",
          title: "Kunne ikke oppdatere profil",
          description: (
            <>
                Mottok følgende feilmelding: <strong>{error?.message ?? "Something went wrong!" } </strong>
            </>
          )
        })
      }
    })
  }

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: currentUser?.FirstName ?? "",
      lastName: currentUser?.LastName ?? "",
      email: currentUser?.Email ?? ""
    }
  })

  useEffect(() => {
    if(!currentUser || !form) return
    form.reset({
      firstName: currentUser?.FirstName ?? "",
      lastName: currentUser?.LastName ?? "",
      email: currentUser?.Email ?? ""
    })
  }, [ currentUser, form ])

  return {
    form,
    loading,
    error,
    onSubmit,
    userIsLoading
  }
}

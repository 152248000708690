import type { ComponentProps } from "react"
import { Slider as SliderBase } from "@/v2-ui/slider/base/SliderBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SliderProps = ComponentProps<typeof SliderBase>

function Slider(props: SliderProps) {
  const { className, disabled, ...sliderProps } = props
  return (
    <SliderBase
      className={cn("ui-slider", className, {
        "ui-slider-disabled": disabled
      })}
      disabled={disabled}
      {...sliderProps}
    />
  )
}

export default Slider

import { forwardRef } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import { Input as InputBase } from "@/v2-ui/input/base/InputBase"
import type { InputProps as InputBaseProps } from "@/v2-ui/input/base/InputBase"

export type InputProps = InputBaseProps

function Input(props: InputProps, ref) {
  const { className, ...inputBaseProps } = props
  return (
    <InputBase
      className={cn("ui-input", className)}
      ref={ref}
      {...inputBaseProps}
    />
  )
}

export default forwardRef(Input)

import Icon from "@/v2-ui/icon"

function StateErrorDefault() {

  return (
    <Icon
      src="not-found"
      size={30}
    />
  )
}

export default StateErrorDefault

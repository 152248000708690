import type { ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import DialogCloseButton from "./DialogCloseButton"
import DialogFullscreenButton from "./DialogFullscreenButton"

export type DialogHeaderActionsProps = ComponentProps<"div"> & {
  isFullscreenButtonEnabled?: boolean,
  isCloseButtonEnabled?: boolean
}

function DialogHeaderActions(props: DialogHeaderActionsProps) {
  const {
    className,
    children,
    isFullscreenButtonEnabled = true,
    isCloseButtonEnabled = true,
    ...frameHeaderProps
  } = props

  return (
    <div
      className={cn("dialog-header-actions", className)}
      {...frameHeaderProps}
    >
      {children}

      {isFullscreenButtonEnabled
        ? <DialogFullscreenButton />
        : null
      }

      {isCloseButtonEnabled
        ? <DialogCloseButton />
        : null
      }
    </div>
  )
}

export default DialogHeaderActions

import { track, type TrackProperties } from "@/v2-console-shared/analytics"
import type { ThemeMode } from "@/v2-ui/theme/theme.types"

export function trackAppSidebarNavigated(
  to: string,
  props?: Partial<TrackProperties>
) {
  track("AppSidebar_Navigated", {
    module: "AppSidebar",
    action: "Navigated",
    to,
    sprintNo: 0,
    issueId: 0,
    ...props
  })
}

export function trackAppThemeModeChangedByUser(mode: ThemeMode) {
  track("Theme_Mode_ChangedByUser", {
    module: "Theme",
    source: "Mode",
    action: "ChangedByUser",
    themeMode: mode,
    issueId: 0,
    sprintNo: 0
  })
}
export function trackAppThemeConfigChangedByUser(field: string, value: string | number) {
  track("Theme_Config_Changed", {
    module: "Theme",
    source: "Config",
    action: "ChangedByUser",
    issueId: 0,
    sprintNo: 0,
    field,
    value
  })
}

// @note: not used atm
// export function trackThemeLogged(themeConfig: ThemeConfig) {
//   track("Theme_Logged", {
//     module: "Theme",
//     action: "Logged",
//     issueId: -1,
//     sprintNo: 103,
//     ...themeConfig
//   })
// }

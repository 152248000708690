import type { ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import { useFrameContext } from "@/v2-ui/frame/frame.context"

export type FrameBackdropProps = ComponentProps<"div">

function FrameBackdrop(props: FrameBackdropProps) {
  const { className, ...divProps } = props
  const { isShown, isClosedOnOutsideClick, onHide } = useFrameContext()
  return (
    <div
      role="presentation"
      aria-hidden={!isShown}
      className={cn("frame-backdrop", className, {
        "is-shown": isShown
      })}
      onClick={() => {
        if(onHide && isClosedOnOutsideClick) {
          onHide()
        }
      }}
      {...divProps}
    />
  )
}

export default FrameBackdrop

import type { ComponentProps } from "react"
import { AvatarImage as AvatarImageBase } from "@/v2-ui/avatar/base/AvatarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type AvatarImageProps = ComponentProps<typeof AvatarImageBase>

function AvatarImage(props: AvatarImageProps) {
  const { className, ...avatarImageBaseProps } = props
  return (
    <AvatarImageBase
      className={cn("ui-avatar-image", className)}
      {...avatarImageBaseProps}
    />
  )
}

export default AvatarImage

import type { ComponentProps } from "react"
import { SidebarMenuSubItem as SidebarMenuSubItemBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarMenuSubItemProps = ComponentProps<typeof SidebarMenuSubItemBase>

function SidebarMenuSubItem(props: SidebarMenuSubItemProps) {
  const { className, ...sidebarMenuSubItemBaseProps } = props
  return (
    <SidebarMenuSubItemBase
      className={cn("ui-sidebar-menu-sub-item", className)}
      {...sidebarMenuSubItemBaseProps}
    />
  )
}

export default SidebarMenuSubItem

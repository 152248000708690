import type { ComponentProps } from "react"
import { SidebarFooter as SidebarFooterBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarFooterProps = ComponentProps<typeof SidebarFooterBase>

function SidebarFooter(props: SidebarFooterProps) {
  const { className, ...sidebarFooterBaseProps } = props
  return (
    <SidebarFooterBase
      className={cn("ui-sidebar-footer", className)}
      {...sidebarFooterBaseProps}
    />
  )
}

export default SidebarFooter

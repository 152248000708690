import { forwardRef, type ComponentProps } from "react"
import { PopoverTrigger as PopoverTriggerCore } from "@/v2-ui/popover/base/PopoverBase"

export type PopoverTriggerProps = ComponentProps<typeof PopoverTriggerCore> & {
  isToggle?: boolean
}

function PopoverTrigger(
  props: PopoverTriggerProps,
  ref
) {
  const {
    asChild = true,
    ...popoverTriggerProps
  } = props

  return (
    <PopoverTriggerCore
      ref={ref}
      asChild={asChild}
      {...popoverTriggerProps}
    />
  )
}

export default forwardRef(PopoverTrigger)

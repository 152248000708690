import type { ComponentProps } from "react"
import { DropdownMenuSub as DropdownMenuSubBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"

export type DropdownMenuSubProps = ComponentProps<typeof DropdownMenuSubBase>

function DropdownMenuSub(props: DropdownMenuSubProps) {
  return <DropdownMenuSubBase {...props} />
}

export default DropdownMenuSub

import type { ComponentProps } from "react"
import { SheetHeader as SheetHeaderBase }
  from "@/v2-ui/sheet/base/SheetBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SheetHeaderProps = ComponentProps<typeof SheetHeaderBase>

function SheetHeader(props: SheetHeaderProps) {
  const { className, ...sheetHeaderBaseProps } = props
  return (
    <SheetHeaderBase
      className={cn("ui-sheet-header", className)}
      {...sheetHeaderBaseProps}
    />
  )
}

export default SheetHeader

import type { ReactNode } from "react"
import { MsalProvider } from "@azure/msal-react"
import { instance } from "@/v2-console/auth/auth.instance"
import AuthNavigation from "@/v2-console/auth/AuthNavigation"
import AuthLoader from "@/v2-console/auth/AuthLoader"

type AuthProviderProps = {
  children: ReactNode
}

function AuthProvider(props: AuthProviderProps) {
  const { children } = props
  return (
    <AuthNavigation instance={instance}>
      <MsalProvider instance={instance}>
        <AuthLoader>
          {children}
        </AuthLoader>
      </MsalProvider>
    </AuthNavigation>
  )
}

export default AuthProvider

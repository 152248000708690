import "./popover.css"
import Popover from "@/v2-ui/popover/Popover"
import PopoverContent from "@/v2-ui/popover/PopoverContent"
import PopoverTrigger from "@/v2-ui/popover/PopoverTrigger"
import PopoverClose from "@/v2-ui/popover/PopoverClose"
import PopoverPortal from "@/v2-ui/popover/PopoverPortal"
import PopoverAnchor from "@/v2-ui/popover/PopoverAnchor"

export type { PopoverProps } from "@/v2-ui/popover/Popover"
export type { PopoverContentProps } from "@/v2-ui/popover/PopoverContent"
export type { PopoverTriggerProps } from "@/v2-ui/popover/PopoverTrigger"
export type { PopoverCloseProps } from "@/v2-ui/popover/PopoverClose"
export type { PopoverPortalProps } from "@/v2-ui/popover/PopoverPortal"
export type { PopoverAnchorProps } from "@/v2-ui/popover/PopoverAnchor"
export default Object.assign(Popover, {
  Content: PopoverContent,
  Trigger: PopoverTrigger,
  Close: PopoverClose,
  Portal: PopoverPortal,
  Anchor: PopoverAnchor
})

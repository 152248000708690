import { useMemo } from "react"
import Button from "@/v2-ui/button"
import type { CurrentUserFragment }
  from "@/v2-console-shared/currentUser/__types__/currentUser.fragment"
import { getUserFullName, getActiveTenantFromCurrentUser }
  from "@/v2-console-shared/user/user.utils"
import CurrentUserAvatar
  from "@/v2-console-ui/currentUser/CurrentUserAvatar"

export type CurrentUserProfileProps = {
  currentUser?: CurrentUserFragment,
  isLoading?: boolean,
  className?: string,
  onShowUserForm?(): void,
}

function CurrentUserProfile(props: CurrentUserProfileProps) {
  const {
    isLoading,
    className,
    currentUser,
    onShowUserForm
  } = props

  const userTenant = useMemo(() => {
    return getActiveTenantFromCurrentUser(currentUser)
  }, [ currentUser ])

  return (
    <div className="w-full flex justify-between items-center px-2 py-2 gap-2">
      <CurrentUserAvatar
        className={className}
        isLoading={isLoading}
        name={getUserFullName(currentUser)}
        subName={`${currentUser?.ActiveTenant?.Name} - ${userTenant?.UserTenantType?.Name}`}
      />
      <Button
        variant="outline"
        className="w-7"
        onClick={onShowUserForm}
        iconSrc="pencil"
        iconSize={12}
      />
    </div>
  )
}

export default CurrentUserProfile

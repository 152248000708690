import "@/v2-console-ui/state/loading/state.loading.css"
import type { StateLoadingType } from "@/v2-console-ui/state/loading/state.loading.types"
import { STATE_LOADING_TYPES } from "@/v2-console-ui/state/loading/state.loading.constants"

export type StateLoadingProps = {
  stateLoadingType?: StateLoadingType
}

function StateLoading(props: StateLoadingProps) {
  const {
    stateLoadingType
  } = props

  if(stateLoadingType === STATE_LOADING_TYPES.LOADING) {
    return (
      <div
        className="state-loading"
      >
        <div className="state-loading-spinner" />
      </div>
    )
  }
}

export default StateLoading

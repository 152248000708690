import type { ComponentProps } from "react"
import { ToastTitle as ToastTitleBase } from "@/v2-ui/toast/base/ToastBase"
import cn from "@/v2-ui/utils/utils.cn"

export type ToastTitleProps = ComponentProps<typeof ToastTitleBase>

function ToastTitle(props: ToastTitleProps) {
  const { className, ...toastTitleBaseProps } = props
  return (
    <ToastTitleBase
      className={cn("ui-toast-title", className)}
      {...toastTitleBaseProps}
    />
  )
}

export default ToastTitle

import type { ComponentProps } from "react"
import { SidebarHeader as SidebarHeaderBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarHeaderProps = ComponentProps<typeof SidebarHeaderBase>

function SidebarHeader(props: SidebarHeaderProps) {
  const { className, ...sidebarHeaderBaseProps } = props
  return (
    <SidebarHeaderBase
      className={cn("ui-sidebar-header", className)}
      {...sidebarHeaderBaseProps}
    />
  )
}

export default SidebarHeader

import type { ComponentProps } from "react"
import { FormLabel as FormLabelBase } from "@/v2-ui/form/base/FormBase"
import cn from "@/v2-ui/utils/utils.cn"

export type FormLabelProps = ComponentProps<typeof FormLabelBase>

function FormLabel(props: FormLabelProps) {
  const { className, ...formBaseProps } = props

  return (
    <FormLabelBase
      className={cn("ui-form-label", className)}
      {...formBaseProps}
    />
  )
}

export default FormLabel

import type { ComponentProps } from "react"
import { DropdownMenuCheckboxItem as DropdownMenuBaseCheckboxItemBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"
import cn from "@/v2-ui/utils/utils.cn"

export type DropdownMenuCheckboxItemProps = ComponentProps<typeof DropdownMenuBaseCheckboxItemBase>

function DropdownMenuCheckboxItem(props: DropdownMenuCheckboxItemProps) {
  const { className, ...dropdownMenuBaseCheckboxItemBaseProps } = props

  return (
    <DropdownMenuBaseCheckboxItemBase
      className={cn("ui-dropdown-menu-checkbox-item", className)}
      {...dropdownMenuBaseCheckboxItemBaseProps}
    />
  )
}

export default DropdownMenuCheckboxItem

import type { ComponentProps } from "react"
import { ToastClose as ToastCloseBase } from "@/v2-ui/toast/base/ToastBase"
import cn from "@/v2-ui/utils/utils.cn"

export type ToastCloseProps = ComponentProps<typeof ToastCloseBase>

function ToastClose(props: ToastCloseProps) {
  const { className, ...toastClToastCloseBaseProps } = props
  return (
    <ToastCloseBase
      className={cn("ui-toast-close", className)}
      {...toastClToastCloseBaseProps}
    />
  )
}

export default ToastClose

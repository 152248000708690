import type { ComponentProps } from "react"
import { FormItem as FormItemBase } from "@/v2-ui/form/base/FormBase"
import cn from "@/v2-ui/utils/utils.cn"

export type FormItemProps = ComponentProps<typeof FormItemBase>

function FormItem(props: FormItemProps) {
  const { className, ...formBaseProps } = props

  return (
    <FormItemBase
      className={cn("ui-form-item", className)}
      {...formBaseProps}
    />
  )
}

export default FormItem

import type { ComponentProps } from "react"
import { useNavigate } from "@/v2-console-shared/utils/utils.router"
import { trackExternalLink }
  from "@/v2-console-ui/link/link.tracking"

export type LinkProps = Omit<ComponentProps<"a">, "href"> & {
  to?: string,
  issueId?: number,
  sprintNo?: number
}

function Link(props: LinkProps) {
  const {
    issueId = 0,
    sprintNo = 0,
    to,
    onClick,
    ...linkProps
  } = props

  const navigate = useNavigate()

  const isExternal = to?.includes("//")
  if(isExternal) {
    linkProps.target = "_blank"
    linkProps.rel = "noopener noreferrer"
  }

  return (
    <a
      title="link"
      href={to}
      onClick={(e) => {
        onClick?.(e)
        if(isExternal) {
         trackExternalLink({
            to,
            title: props.title,
            issueId,
            sprintNo
          })
        }
        else if(linkProps.target !== "_blank") {
          e.preventDefault()
          navigate(to)
        }
      }}
      {...linkProps}
    />
  )
}

export default Link

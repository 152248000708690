import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { idToken } from "@/v2-console/auth/auth.instance"
import config from "@/v2-console-shared/config"

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.socketUrl,
    connectionParams() {
      return {
        token: idToken
      }
    }
  })
)

export default wsLink

import type { ComponentProps } from "react"

export type PlacepointLogoProps = ComponentProps<"svg">

function PlacepointLogo(props: PlacepointLogoProps) {

  const {
    fill,
    width = 24,
    height = 24,
    ...svgProps
  } = props

  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      viewBox="0 0 1792 1225"
      {...svgProps}
    >
      <path
        className={fill ? "" : "fill-background"}
        d="M1247.88 75.5678C1116.36 14.8867 1007.6 0.980634 884.927 25.0002C860.899 30.057 839.382 35.3953 815.354 42.9805L27.4989 279.102C14.3535 282.98 6 293.008 6 308.179C6 319.556 12.3232 329.67 22.4404 335.991L56.5857 353.69L473.918 573.659C623.146 641.925 792.949 649.596 948.5 596.5L1660.15 339.784L1700.62 325.877C1712 322.085 1720.86 310.707 1720.86 298.065C1720.86 286.688 1718.33 277.838 1704.42 272.781L1247.88 75.5678ZM85.6725 299.329C85.6725 299.329 759.707 98.4609 819.854 79.4805C880 60.5 856.427 67.891 881.5 60C906.573 52.109 971.5 43.5086 1019.5 47C1067.5 50.4914 1120.85 61.4805 1211.21 100.852C1301.56 140.223 1648.77 290.48 1648.77 290.48L933 522C850.798 546.02 766.051 552.167 682.584 543.318C611.764 535.733 542.209 514.242 477.712 483.901L85.6725 299.329Z"
      />
      <path
        className={fill ? "" : "fill-background"}
        d="M34 479.5C22.6182 483.293 15.1777 494.296 15.1777 508.202C15.1777 518.316 20.1758 528.014 28 533.5C35.8242 538.986 187.169 640.942 187.169 640.942L445.157 815.4C548.857 883.666 671.528 917.8 795.463 902.629C815.697 900.101 834.667 896.308 853.637 892.516C882.723 886.195 910.545 876.081 937.103 863.439L1700.95 527.165C1721.18 518.316 1724.98 480.39 1704.74 469.012L1585.87 408.331L1535.28 427.294L1649.1 486.711C1649.1 486.711 977 770.808 880 812C783 853.192 662.699 837.875 542.5 780.5C422.301 723.125 102.438 504.41 102.438 504.41L209.933 470.276L151.759 439.936L34 479.5Z"
      />
      <path
        className={fill ? "" : "fill-background"}
        d="M1699.69 649.796C1711.07 654.853 1719.92 667.495 1719.92 680.136C1719.92 692.778 1712.33 701.628 1703.48 707.949C1703.48 707.949 922.5 1137 872 1165C821.5 1193 715.794 1190.87 715.794 1190.87C632.327 1190.87 557 1157.5 494.5 1104.5C494.5 1104.5 33.5965 716.426 25.2983 709.213C17 702 13.3623 692.83 15.1811 682.665C16.9999 672.5 27.9999 665 36.4999 662C44.9999 659 111.294 635.89 111.294 635.89L156.821 666.23L92.3243 687.722C92.3243 687.722 377.5 928 494.5 1026.5C611.5 1125 771 1150 868.5 1096.5C966 1043 1641.51 671.287 1641.51 671.287L1564.37 632.097L1614.96 609.342L1699.69 649.796Z"
      />
    </svg>
  )
}

export default PlacepointLogo

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {

  return (
    <div
    className={className}
      {...props}
    />
  )
}

export { Skeleton }

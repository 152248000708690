import type { ComponentProps } from "react"
import { Form as FormBase } from "@/v2-ui/form/base/FormBase"

export type FormProps = ComponentProps<typeof FormBase>

function Form(props: FormProps) {
  const { ...formBaseProps } = props

  return (
    <FormBase
      {...formBaseProps}
    />
  )
}

export default Form

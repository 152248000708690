import type { ComponentProps } from "react"
import { SidebarMenuBadge as SidebarMenuBadgeBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarMenuBadgeProps = ComponentProps<typeof SidebarMenuBadgeBase>

function SidebarMenuBadge(props: SidebarMenuBadgeProps) {
  const { className, ...sidebarMenuBadgeBaseProps } = props
  return (
    <SidebarMenuBadgeBase
      className={cn("ui-sidebar-menu-badge", className)}
      {...sidebarMenuBadgeBaseProps}
    />
  )
}

export default SidebarMenuBadge

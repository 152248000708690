import type { ComponentProps } from "react"
import { forwardRef } from "react"
import { DropdownMenuContent as DropdownMenuBaseContentBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"
import type { VariantProps } from "class-variance-authority"
import { cva } from "class-variance-authority"

const dropdownContentVariations = cva("ui-dropdown-menu-content", {
  variants: {
    size: {
      default: "size-default",
      lg: "size-lg",
      md: "size-md"
    }
  },
  defaultVariants: {
    size: "default"
  }
})

export type DropdownMenuContentProps = ComponentProps<typeof DropdownMenuBaseContentBase>
  & VariantProps<typeof dropdownContentVariations>

function DropdownMenuContent(props: DropdownMenuContentProps, ref) {
  const { size, className, ...dropdownMenuBaseContentBaseProps } = props

  return (
    <DropdownMenuBaseContentBase
      ref={ref}
      className={dropdownContentVariations({ size, className })}
      {...dropdownMenuBaseContentBaseProps}
    />
  )
}

export default forwardRef(DropdownMenuContent)

import type { ComponentProps } from "react"
import { SidebarGroupContent as SidebarGroupContentBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarGroupContentProps = ComponentProps<typeof SidebarGroupContentBase>

function SidebarGroupContent(props: SidebarGroupContentProps) {
  const { className, ...sidebarGroupContentBaseProps } = props
  return (
    <SidebarGroupContentBase
      className={cn("ui-sidebar-group-content", className)}
      {...sidebarGroupContentBaseProps}
    />
  )
}

export default SidebarGroupContent

import type { ComponentProps } from "react"
import { SidebarGroupLabel as SidebarGroupLabelBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarGroupLabelProps = ComponentProps<typeof SidebarGroupLabelBase>

function SidebarGroupLabel(props: SidebarGroupLabelProps) {
  const { className, ...sidebarGroupLabelBaseProps } = props
  return (
    <SidebarGroupLabelBase
      className={cn("ui-sidebar-group-label", className)}
      {...sidebarGroupLabelBaseProps}
    />
  )
}

export default SidebarGroupLabel

import type { ComponentProps } from "react"
import { SidebarContent as SidebarContentBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarContentProps = ComponentProps<typeof SidebarContentBase>

function SidebarContent(props: SidebarContentProps) {
  const { className, ...sidebarContentBaseProps } = props
  return (
    <SidebarContentBase
      className={cn("ui-sidebar-content", className)}
      {...sidebarContentBaseProps}
    />
  )
}

export default SidebarContent

import { useEffect, useState, type ComponentProps } from "react"
// import { type ComponentProps, useEffect, useRef } from "react"
// import { useIsAuthenticated } from "@azure/msal-react"
// import type { Timeout } from "@/v2-common/types"
import PlacepointLogo from "@/v2-ui/PlacepointLogo"
// import { useNavigate, useLocation }
//   from "@/v2-console-shared/utils/utils.router"
import { useLocation } from "@/v2-console-shared/utils/utils.router"
import Link from "@/v2-console-ui/link"
import AuthError from "@/v2-console/auth/AuthError"
import AuthLoginButton from "@/v2-console/auth/AuthLoginButton"

// const REDIRECT_DELAY = 2500

type AuthLoginPageProps = ComponentProps<"div">

function AuthLoginPage(props: AuthLoginPageProps) {
  // const navigate = useNavigate()
  // const isAuthenticated = useIsAuthenticated()
  const [ error, setError ] = useState<Error>()
  const location = useLocation<{ error?: Error }>()
  const locationError = location.state?.error

  useEffect(() => {
    if(locationError) {
      // Prevents error from being shown after a refresh
      window.history.replaceState({}, document.title)
      setError(locationError)
    }
  }, [
    locationError
  ])

  // @todo: make sure the redirect business works with e2e tests first

  // const hasRedirected = useRef(false)
  // // const timeout = useRef<Timeout>()

  // useEffect(() => {
  //   if(!isAuthenticated || hasRedirected.current) return
  //   // @todo: dont attempt to navigate if already attempted
  //   //        to avoid endless redirect loop
  //   // @todo: add a timeout before next (redirect) iteration
  //   const url = location.search.split("r=")?.[1]
  //   if(url) {
  //     // timeout.current = setTimeout(() => {
  //       // @todo: only clear the "r" param
  //       navigate(url, {}, false)
  //     // }, REDIRECT_DELAY)

  //     hasRedirected.current = true

  //     // return () => {
  //     //   if(timeout.current) {
  //     //     clearTimeout(timeout.current)
  //     //   }
  //     // }
  //   }
  // }, [
  //   navigate,
  //   isAuthenticated,
  //   location
  // ])

  return (
    <div
      className="auth-login-page h-screen bg-black flex items-center justify-center flex-col flex-1 pb-24"
      {...props}
    >
      <AuthError
        className="mb-4"
        error={error}
      />

      <div
        className="w-full bg-white text-black p-6 rounded-lg flex flex-col justify-center items-baseline shadow-lg max-w-[18rem]"
      >
        <div className="flex items-center">
          <PlacepointLogo
            className="w-10"
            fill="black"
          />

          <h1 className="ml-2 text-sm">
            Placepoint Console
          </h1>
        </div>

        <h2 className="text-xl mt-12 mb-4">
          Vennligst logg inn for å fortsette
        </h2>

        <p className="text-sm">
          Har du ikke en konto? Fyll ut skjema på
          <Link
            className="auth-login__to-marketing-site border-b border-pr3 mx-1"
            to="https://placepoint.no"
          >
            landingssiden vår
          </Link>, så tar vi kontakt med deg angående tilgang.
        </p>

        <AuthLoginButton
          className="mt-6"
        />
      </div>
    </div>
  )
}

export default AuthLoginPage

import { useState, useCallback, useEffect } from "react"
import { setLocale } from "@arcgis/core/intl"
import { useApolloClient } from "@apollo/client"
import { SUPPORTED_ARCGIS_LANGUAGE_CODES }
  from "@/v2-map-ui/map.constants"
import type { CurrentUserFragment }
  from "@/v2-console-shared/currentUser/__types__/currentUser.fragment"
import type { CurrentUserLanguageFragment }
  from "@/v2-console-shared/currentUser/language/__types__/currentUser.language.fragment"
import { useAppContext } from "@/v2-console/app/context/AppContextProvider"
import useCurrentUserLanguageUpdateMutation
  from "@/v2-console/currentUser/language/update/currentUser.language.update.mutation"
import { useCurrentUserLanguageLazyQuery }
  from "@/v2-console/currentUser/language/currentUser.language.query"
import {
  trackCurrentUserLanguageChanged,
  trackCurrentUserLanguageChangedWithUnsupportedArcGisLanguage
} from "@/v2-console/currentUser/currentUser.tracking"
import { LANGUAGE_CODE_LOCALSTORAGE_KEY }
  from "@/v2-console/currentUser/language/currentUser.language.constants"

/**
 * Updates the ArcGIS language setting based on the provided language code.
 *
 * @param languageCode - The language code (e.g., "en-US") to update ArcGIS language settings.
 * If the language is not supported, it defaults to English.
 */

export function updateArcGisLanguage(languageCode: string) {
  let languageIdentifier = languageCode.split("-")[0]

  // @note: if language is not supported by arcgis, default to english
  if(!SUPPORTED_ARCGIS_LANGUAGE_CODES[languageIdentifier]) {
    trackCurrentUserLanguageChangedWithUnsupportedArcGisLanguage(languageCode)
    languageIdentifier = "en"
  }

  setLocale(languageIdentifier)
}

export function getLanguageFromLocalStorage() {
  return window.localStorage.getItem(LANGUAGE_CODE_LOCALSTORAGE_KEY)
}

function setLanguageToLocalStorage(languageCode: string) {
  window.localStorage.setItem(LANGUAGE_CODE_LOCALSTORAGE_KEY, languageCode)
}

/**
 * Retrieves the language code from the browser's settings.
 *
 * @returns Returns the browser's language in 5-letter format (e.g., "en-US"). If not available, returns the default language code.
 */
export function getLanguageFromBrowser() {
  return navigator.language
}

// @todo: not used
/**
 * Retrieves the current user's active language code, if available.
 *
 * @param currentUser - The current user object, optionally provided.
 * @returns The active language code if set, or a fallback from local storage or browser settings.
 */
export function getLanguageCode(currentUser?: CurrentUserFragment) {
  if(currentUser?.ActiveLanguageCode) return currentUser.ActiveLanguageCode

  const localStorageLanguageCode = getLanguageFromLocalStorage()
  if(localStorageLanguageCode) return localStorageLanguageCode

  const browserLanguge = getLanguageFromBrowser()
  // @todo: add a check to see if the browser language is supported
  setLanguageToLocalStorage(browserLanguge)

  return browserLanguge
}

/**
 * Custom hook to manage the current user's active language.
 * It fetches the user's language settings, updates them, and manages language-related state.
 *
 * @returns An object containing the active language, loading state, any query/mutation errors,
 * the available languages, and a function to change the user's language.
 */
export const useCurrentUserLanguage = () => {
  const [ activeLanguage, setActiveLanguage ] = useState<CurrentUserLanguageFragment>()

  const client = useApolloClient()
  const { currentUser } = useAppContext()

  const [ getLanguagesQuery, { data, error: queryError, loading: isQueryLoading }]
    = useCurrentUserLanguageLazyQuery()

  const [ updateLanguageMutation, { error: updateQueryError, loading: isUpdateQueryLoading }]
    = useCurrentUserLanguageUpdateMutation()

  useEffect(() => {
    if(!currentUser) return
    getLanguagesQuery()
  }, [ getLanguagesQuery, currentUser ])

  useEffect(() => {
    if(!data?.getLanguages || !currentUser) return
    const matchedLanguage = data.getLanguages.find(
      language => language.Code === currentUser.ActiveLanguageCode
    )
    setActiveLanguage(matchedLanguage)
  }, [ currentUser, data?.getLanguages ])

  const onLanguageChange = useCallback((language: CurrentUserLanguageFragment) => {
    updateLanguageMutation({
      variables: {
        input: {
          languageCode: language.Code
        }
      },
      onCompleted: () => {
        // @note: we need to reset client cache in order to refetch
        // all queries  with the new language
        setActiveLanguage(null)
        client.resetStore()
        // update arcgis language
        updateArcGisLanguage(language.Code)
        // persist language to local storage
        setLanguageToLocalStorage(language.Code)
        // track language change
        trackCurrentUserLanguageChanged(language.Code)
      }
    })
  }, [ updateLanguageMutation, client ])

  const loading = !activeLanguage || isQueryLoading || isUpdateQueryLoading
  const error = queryError || updateQueryError
  const languages = data?.getLanguages || []

  return {
    activeLanguage,
    loading,
    error,
    languages,
    onLanguageChange
  }
}

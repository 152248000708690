import type { ComponentProps } from "react"
import { CommandList as CommandListBase } from "@/v2-ui/command/base/CommandBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CommandListProps = ComponentProps<typeof CommandListBase>

function CommandList(props: CommandListProps) {
  const { className, ...commandListBaseProps } = props
  return (
    <CommandListBase
      className={cn("ui-command-list", className)}
      {...commandListBaseProps}
    />
  )
}

export default CommandList

import type { ComponentProps } from "react"
import { CardFooter as CardFooterBase } from "@/v2-ui/card/base/CardBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CardFooterProps = ComponentProps<typeof CardFooterBase>

function CardFooter(props: CardFooterProps) {
  const { className, ...cardFooterBaseProps } = props
  return (
    <CardFooterBase
      className={cn("ui-card-footer", className)}
      {...cardFooterBaseProps}
    />
  )
}

export default CardFooter

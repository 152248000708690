import { useCallback, useEffect, useMemo } from "react"
import { safeJSONParse } from "@/v2-common/utils/utils.misc"
import { getValue } from "@/v2-ui/utils/utils.localStorage"
import type { ThemeConfig } from "@/v2-ui/theme/theme.types"
import {
  type ThemeProviderProps
} from "@/v2-ui/theme/ThemeProvider"
import {
  ThemeContext
} from "@/v2-ui/theme/theme.context"
import { LOCAL_STORAGE_KEY }
  from "@/v2-ui/theme/theme.constants"
import {
  assertIsTheme,
  useTheme
} from "@/v2-ui/theme/theme.utils"

export type ThemeProviderWithLocalStorageProps = ThemeProviderProps & {
  storageKey?: string
}

function ThemeProviderWithLocalStorage(
  props: ThemeProviderWithLocalStorageProps
) {
  const {
    storageKey = LOCAL_STORAGE_KEY,
    defaultTheme,
    onChange,
    onMount,
    children
  } = props

  // get the intial theme form localStorage
  const initialStorageValue = useMemo(
    () => getValue(storageKey) || defaultTheme,
    [ storageKey, defaultTheme ] // @todo: type to themeconfig
  )

  const onChangeWithStorage = useCallback((
    theme: ThemeConfig
  ) => {
    onChange?.(theme)
    window.localStorage.setItem(storageKey, JSON.stringify(theme))
  }, [ onChange, storageKey ])

  const { theme, setTheme, toggleDarkMode } = useTheme({
    defaultTheme: initialStorageValue as ThemeConfig,
    onChange: onChangeWithStorage,
    onMount
  })

  // localStorage event handling to sync across tabs
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if(e.key !== storageKey) {
        return
      }

      const parsedValue = safeJSONParse(e.newValue)
      const theme = assertIsTheme(parsedValue) ? parsedValue : defaultTheme
      setTheme(theme)
    }

    window.addEventListener("storage", handleStorage)
    return () => {
      window.removeEventListener("storage", handleStorage)
    }
  }, [ setTheme, defaultTheme, storageKey ])

  return (
    <ThemeContext.Provider value={{
      theme,
      setTheme,
      toggleDarkMode
    }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProviderWithLocalStorage

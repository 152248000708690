import Sidebar from "@/v2-ui/sidebar"
import PlacepointLogo from "@/v2-ui/PlacepointLogo"

function SidebarBranding() {
  return (
    <Sidebar.Header>
      <Sidebar.Menu>
        <Sidebar.Menu.Item>
          <Sidebar.Menu.Button size="lg">
              <div className="flex aspect-square size-9 items-center justify-center rounded-md bg-primary">
                <PlacepointLogo />
              </div>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="font-semibold">Placepoint</span>
                <span className="truncate text-xs">Version 2</span>
              </div>
          </Sidebar.Menu.Button>
        </Sidebar.Menu.Item>
      </Sidebar.Menu>
    </Sidebar.Header>
  )
}

export default SidebarBranding

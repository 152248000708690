import { useLocation } from "react-router-dom"
import { useText } from "@/v2-console-ui/text/text.context"
import Sidebar from "@/v2-layout-ui/sidebar"
import { useNavigate } from "@/v2-console-shared/utils/utils.router"
import config from "@/v2-console-shared/config"
import AppSidebarCurrentUserItem from "@/v2-console/app/sidebar/AppSidebarCurrentUserItem"
import AppSidebarItemLanguage from "@/v2-console/app/sidebar/AppSidebarItemLanguage"
import AppSidebarItemCountry from "@/v2-console/app/sidebar/AppSidebarItemCountry"
import AppSidebarItemTheme from "@/v2-console/app/sidebar/AppSidebarItemTheme"
import AppSidebarItemThemeToggle from "@/v2-console/app/sidebar/AppSidebarItemThemeToggle"
import { trackAppSidebarNavigated } from "@/v2-console/app/app.tracking"

function AppSidebar() {
  const navigate = useNavigate()
  const location = useLocation()
  const { loading, getText } = useText()

  function getIsActiveByPath(path: string) {
    return location.pathname.includes(path)
  }

  return (
    <Sidebar>

      <Sidebar.Branding />

      <Sidebar.Content>
        <Sidebar.Group>
          <Sidebar.Item
            className="app-sidebar__to-settings"
            iconSrc="layers"
            label={getText("UI_Layers_Root").Name}
            isActive={getIsActiveByPath("map/layers")}
            onClick={() => {
              if(getIsActiveByPath("map/layers")) {
                navigate("/map")
              }
              if(!getIsActiveByPath("map/layers")) {
                navigate("/map/layers")
              }
              trackAppSidebarNavigated("Layers")
            }}
            isLoading={loading}
          />
        </Sidebar.Group>

        <Sidebar.Group className="mt-auto">
          <AppSidebarItemThemeToggle
            className="app-sidebar__to-themeToggle pr-2"
            isLoading={loading}
          />
          <AppSidebarItemTheme
            className="app-sidebar__to-theme pr-2"
            isLoading={loading}
          />
          <AppSidebarItemCountry
            className="app-sidebar__to-countrySwitcher pr-2"
            isLoading={loading}
          />
          <AppSidebarItemLanguage
            className="app-sidebar__to-languageSwitcher pr-2"
            isLoading={loading}
          />
          <Sidebar.Item
            className="app-sidebar__to-settings"
            iconSrc="settings"
            isActive={getIsActiveByPath("user/settings")}
            onClick={() => {
              if(getIsActiveByPath("/map/user/settings")) {
                navigate("/map")
              }
              if(!getIsActiveByPath("/map/user/settings")) {
                navigate("/map/user/settings")
              }
              trackAppSidebarNavigated("Settings")
            }}
            isLoading={loading}
            label={getText("UI_Settings_Root").Name}
          />
          <Sidebar.Item
            className="app-sidebar__to-support"
            iconSrc="interrogation"
            onClick={() => {
              window.open(config.docsUrl)
              trackAppSidebarNavigated("Docs")
            }}
            isLoading={loading}
            label={getText("UI_Help_Root").Name}
          />
        </Sidebar.Group>

      </Sidebar.Content>
      <Sidebar.Separator />
      <Sidebar.Footer>
        <AppSidebarCurrentUserItem
          loading={loading}
        />
      </Sidebar.Footer>
    </Sidebar>
  )
}

export default AppSidebar

import type { ComponentProps } from "react"
import { PopoverPortal as PopoverPortalBase } from "@/v2-ui/popover/base/PopoverBase"

type BaseProps = ComponentProps<typeof PopoverPortalBase>
export type PopoverPortalProps = BaseProps

function PopoverPortal(props: PopoverPortalProps) {
  const { ...popoverPortalBaseProps } = props

  return (
    <PopoverPortalBase
      {...popoverPortalBaseProps}
    />
  )
}

export default PopoverPortal

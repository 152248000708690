import type { ComponentProps } from "react"
import { DropdownMenuItem as DropdownMenuBaseItemBase } from "@/v2-ui/dropdownMenu/base/DropdownMenuBase"
import cn from "@/v2-ui/utils/utils.cn"

export type DropdownMenuItemProps = ComponentProps<typeof DropdownMenuBaseItemBase>

function DropdownMenuItem(props: DropdownMenuItemProps) {
  const { className, ...dropdownMenuBaseItemBaseProps } = props

  return (
    <DropdownMenuBaseItemBase
      className={cn("ui-dropdown-menu-item", className)}
      {...dropdownMenuBaseItemBaseProps}
    />
  )
}

export default DropdownMenuItem

import type { ComponentProps } from "react"
import { ToastViewport as ToastViewportBase } from "@/v2-ui/toast/base/ToastBase"
import cn from "@/v2-ui/utils/utils.cn"

export type ToastViewportProps = ComponentProps<typeof ToastViewportBase>

function ToastViewport(props: ToastViewportProps) {
  const { className, ...toastViewportBaseProps } = props
  return (
    <ToastViewportBase
      className={cn("ui-toast-viewport", className)}
      {...toastViewportBaseProps}
    />
  )
}

export default ToastViewport

import type { ComponentProps } from "react"
import { SidebarMenuSkeleton as SidebarMenuSkeletonBase }
  from "@/v2-ui/sidebar/base/SidebarBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SidebarMenuSkeletonProps = ComponentProps<typeof SidebarMenuSkeletonBase>

function SidebarMenuSkeleton(props: SidebarMenuSkeletonProps) {
  const { className, ...sidebarMenuSkeletonBaseProps } = props
  return (
    <SidebarMenuSkeletonBase
      className={cn("ui-sidebar-menu-skeleton", className)}
      {...sidebarMenuSkeletonBaseProps}
    />
  )
}

export default SidebarMenuSkeleton

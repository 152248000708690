import type { ComponentProps } from "react"
import { Skeleton as SkeletonBase } from "@/v2-ui/skeleton/base/SkeletonBase"
import cn from "@/v2-ui/utils/utils.cn"

export type SkeletonProps = ComponentProps<typeof SkeletonBase>

function Skeleton(props: SkeletonProps) {
  const { className, ...skeletonBaseProps } = props
  return (
    <SkeletonBase
      className={cn("ui-skeleton", className)}
      {...skeletonBaseProps}
    />
  )
}

export default Skeleton

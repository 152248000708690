import { type ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"

type ThemeCustomizerFontSizePreviewProps = ComponentProps<"div"> & {
  fontSize: number
}
function ThemeCustomizerFontSizePreview(props: ThemeCustomizerFontSizePreviewProps) {
  const {
    fontSize,
    className,
    ...divProps
  } = props

  return (
    <div
      className={cn("flex flex-col justify-center p-4 bg-muted rounded-md h-[80px]", className)}
      style={{ fontSize: `${fontSize}px` }}
      {...divProps}
    >
      <div className="font-bold">Preview text with new font size </div>
      <div
        className="text-muted-foreground"
        style={{ fontSize: `${fontSize * 0.875}px` }}
      >
        Preview text with new font size
      </div>
    </div>
  )
}

export default ThemeCustomizerFontSizePreview

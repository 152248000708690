import cn from "@/v2-ui/utils/utils.cn"
import Button, { type ButtonProps } from "@/v2-ui/button"
import type { CurrentUserFragment } from "@/v2-console-shared/currentUser/__types__/currentUser.fragment"
import CurrentUserAvatar from "@/v2-console-ui/currentUser/CurrentUserAvatar"

export type CurrentUserMenuTenantItemProps = ButtonProps & {
  userTenant: CurrentUserFragment["UserTenants"][0],
  isActive?: boolean,
}

function CurrentUserMenuTenantItem(props: CurrentUserMenuTenantItemProps) {
  const {
    userTenant,
    isActive,
    isLoading,
    className,
    ...buttonProps
  } = props

  return (
    <Button
      variant="ghost"
      iconPosition="right"
      iconSrc={isActive
        ? "check-circle"
        : "circle"
      }
      aria-label={isActive
        ? "Dette medlemskapet er aktivt"
        : "Sett som aktiv"
      }
      className={cn("relative rounded flex w-full justify-start text-left py-1.5 h-auto gap-2", className)}
      {...buttonProps}
    >
      <CurrentUserAvatar
        isLoading={isLoading}
        name={userTenant.Tenant.Name}
        subName={userTenant.UserTenantType.Name}
        avatarSize={7}
      />
    </Button>
  )
}

export default CurrentUserMenuTenantItem

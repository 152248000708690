import type { ComponentProps } from "react"
import { CommandSeparator as CommandSeparatorBase } from "@/v2-ui/command/base/CommandBase"
import cn from "@/v2-ui/utils/utils.cn"

export type CommandSeparatorProps = ComponentProps<typeof CommandSeparatorBase>

function CommandSeparator(props: CommandSeparatorProps) {
  const { className, ...commandSeparatorBaseProps } = props
  return (
    <CommandSeparatorBase
      className={cn("ui-command-separator", className)}
      {...commandSeparatorBaseProps}
    />
  )
}

export default CommandSeparator
